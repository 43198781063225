<template>
  <BreadCrumb PageTitle="Text Truncation" />
  <TextTruncation />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BreadCrumb from "../../template-components/Common/BreadCrumb.vue";
import TextTruncation from "../../template-components/Utilities/TextTruncation/TextTruncation.vue";

export default defineComponent({
  name: "TextTruncationPage",
  components: {
    BreadCrumb,
    TextTruncation,
  },
});
</script>