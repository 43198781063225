<template>
  <BreadCrumb PageTitle="Special Projects List" />
  <SpecialProjectsList />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import SpecialProjectsList from "../../components/SpecialProjects/SpecialProjectsList.vue";

export default defineComponent({
  name: "SpecialProjectsListPage",
  components: {
    BreadCrumb,
    SpecialProjectsList,
  },
});
</script>