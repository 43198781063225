<script setup>
import { canReleaseOrder, canHoldOrder, holdOrder, releaseOrder} from '/src/utils/helper';

</script>

<template>
  <!-- Overlay -->
  <div v-if="isLoading" class="full-overlay">
    <span class="spinner-border text-light spinner-overlay" role="status" aria-hidden="true"></span>
  </div>
  <!-- Modal -->
  <div class="modal fade" id="issueModal" tabindex="-1" aria-hidden="true">

    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Create issue for order #{{ orderDetails.order_number }}</h1>
          <button type="button" class="btn-close" id="modalDismiss" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form class="was-validated">
          <div class="modal-body">
            <div class="mb-mb-15 mb-md-20">
              <label for="overviewEmail" class="form-label fw-medium">Type of issue:</label>
              <select id="issueType" class="form-select shadow-none text-black fs-md-15" required>
                <option value="Bundle Issue">Bundle Issue</option>
                <option value="Wrong Address">Wrong Address</option>
                <option value="Wrong Products Set">Wrong Products Set</option>
                <option value="Missing Product">Missing Product</option>
                <option value="Wrong Order Sent">Wrong Order Sent</option>
                <option value="Damaged Order Product">Damaged Order Product</option>
                <option value="Tracking Not Updated">Tracking Not Updated</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div class="mb-15 mb-md-20">
                <label for="issueDescription" class="form-label fw-medium">Description</label>
                <textarea class="form-control shadow-none fs-md-15 text-black" id="issueDescription" placeholder="Required example textarea" required></textarea>
                <div class="invalid-feedback">
                    Notes - Enter more information about the issue. (No need to include order numbers.)
                </div>
            </div>
            <!-- <div class="mb-15 mb-md-20">
              <ul v-if="orderDetails.order_number > 0" style="padding:0px">
                <li v-for="item in orderDetails.items.data" :key="item.id" style="margin:0px;list-style-type: none;">
                  <div class="input-group input-group-sm mb-15 mb-md-20" style="margin-bottom:0px !important">
                      <span class="input-group-text" id="inputGroup-sizing-sm">Quantity:</span>
                      <input type="number" class="form-control shadow-none text-black" value="0" style="max-width: 100px;" :data-item-id="item.id"  min="0">
                      <span class="input-group-text" id="basic-addon2">{{ item.stock_product.data.name }}</span>
                  </div>
                </li>
              </ul>
            </div> -->
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-success" :disabled="isLoading" @click="submitIssue">Submit Issue</button>
          </div>
        </form>
      </div>

    </div>
  </div>
  <div class="modal fade" id="editShippingModal" tabindex="-1" aria-hidden="true">

    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Edit shipping options for order #{{ orderDetails.order_number }}</h1>
          <button type="button" class="btn-close" id="modalDismiss" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form>
          <div class="modal-body">
            <div class="mb-mb-15 mb-md-20">
              <label for="shippingServicesSelect" class="form-label fw-medium">Shipping Service:</label>
              <select id="shippingServicesSelect" v-model="inputShippingServiceSlug" class="form-select shadow-none text-black fs-md-15" required>
                <option v-for="shippingService in shippingServices" :key="shippingService.id" :value="shippingService.slug">{{ shippingService.display_name }}{{ shippingService.shipping_rate && shippingService.shipping_rate.delivery_days ? ': '+shippingService.shipping_rate.delivery_days + ' Days' + (shippingService.shipping_rate.delivery_date_guaranteed ? ' Guaranteed' : '') : (shippingService.etd ? ': '+shippingService.etd : '') }} {{ shippingService.shipping_rate ? '($' + shippingService.shipping_rate.rate + ')' : '' }}</option>
              </select>
            </div>
            <div class="mb-15 mb-md-20">
              <label for="shippingPriorityLevelSelect" class="form-label fw-medium">Handling Priority:</label>
              <select id="shippingPriorityLevelSelect" v-model="inputShippingPriority" class="form-select shadow-none text-black fs-md-15" required>
                <option value="0" :key="0">Normal</option>
                <option value="1" :key="1">High</option>
                <option value="2" :key="2">Urgent</option>
              </select>
            </div>
            <div class="mb-15 mb-md-20">
              <input class="form-check-input shadow-none" id="bypassAddressValidationSelect" type="checkbox" v-model="inputBypassAddressValidation" :checked="inputBypassAddressValidation ? true : false" value="1" /> <label for="bypassAddressValidationSelect" class="form-check-label fw-medium position-relative top-1">Bypass address validation</label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-success" :disabled="isLoading" @click="updateShippingOptions">Update Options</button>
          </div>
        </form>
      </div>

    </div>
  </div>

  <div class="row">
    <!-- Order Details Section -->
    <div class="col-lg-12 col-xl-12 col-xxl-4">
      <div
        class="card mb-25 border-0 rounded-0 bg-white order-details-box letter-spacing"
      >
        <div class="card-head bg-white d-flex align-items-center">
          <i class="flaticon-sterile-box text-primary"></i>
          <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15">
            Order Details (#{{ orderDetails.order_number }})
          </h5>
        </div>
        <div class="card-body">
          <ul class="list ps-0 mb-0 list-unstyled">
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-document"></i>
                Transaction ID:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                {{ orderDetails.transaction_id }}
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-user-1"></i>
                Customer Name:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                {{ orderDetails.customer_first_name }} {{ orderDetails.customer_last_name }}
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-envelope"></i>
                Email:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                {{ orderDetails.customer_email }}
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-phone-call"></i>
                Phone:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                {{ orderDetails.customer_phone }}
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-calendar"></i>
                Ordered At:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                {{ formatDate(orderDetails.ordered_at) }}
              </span>
            </li>

            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="ph ph-package"></i>
                Fulfillment Status:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                <code>{{ orderDetails.fulfillment_status }}</code>
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-play"></i>
                Actions:
              </div>

              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                <span v-if="canReleaseOrder(orderDetails.fulfillment_status_id)">
                  <button type="button" class="btn btn-success btn-sm" @click="handleReleaseOrder(orderDetails.id)" :disabled="isLoading" style="padding: 5px 6px;margin-right:5px">
                    <!-- <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>  -->
                    Release
                  </button>
                  <button type="button" class="btn btn-secondary btn-sm" @click="openEditShippingModal" :disabled="isLoading" style="padding: 5px 6px;margin-right:5px">
                    <!-- <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> -->
                    Edit Shipping
                  </button>
                </span>
                <span v-else-if="canHoldOrder(orderDetails.fulfillment_status_id)">
                  <button type="button" class="btn btn-secondary btn-sm" @click="handleHoldOrder(orderDetails.id)" :disabled="isLoading" style="padding: 5px 6px;margin-right:5px">
                    <!-- <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>  -->
                    Hold Order
                  </button>
                </span>

                <span>
                  <button data-bs-toggle="modal" data-bs-target="#issueModal" type="button" class="btn btn-danger btn-sm" style="padding: 5px 6px; margin-right:5px">Create Issue</button>
                </span>
                <span v-if="orderDetails.transaction_id">
                  <a class="btn btn-info btn-sm" target="_blank" :href="orderDetails.external_order_url" style="padding: 5px 6px">Open in Shopify</a>
                </span>
              </span>

            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xl-12 col-xxl-4">
      <div
        class="card mb-25 border-0 rounded-0 bg-white order-details-box letter-spacing"
      >
        <div class="card-head bg-white d-flex align-items-center">
          <i class="ph ph-address-book text-primary"></i>
          <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15">
            Address
          </h5>
        </div>
        <div class="card-body">
          <ul class="list ps-0 mb-0 list-unstyled">
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-pin"></i>
                Address Street:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                {{ orderDetails.customer_address_street }}
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-map"></i>
                City/State:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">

                {{ orderDetails.customer_address_city }}, {{ orderDetails.customer_address_state }}, {{ orderDetails.customer_address_country_code }}
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-gps"></i>
                Zip:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                {{ orderDetails.customer_address_zip }}
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-check-mark"></i>
                Bypass validation:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                {{ orderDetails.bypass_address_validation ? 'Yes' : 'No' }}
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between" v-if="orderDetails.shipments && orderDetails.shipments.data.length > 0">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-map"></i>
                Tracking code:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                <code v-for="shipment in orderDetails.shipments.data" :key="shipment.id" style="margin:0px;list-style-type: none;">
                  {{ shipment.tracking_code }}
                </code>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-lg-6 col-xl-12 col-xxl-4">
      <div
        class="card mb-25 border-0 rounded-0 bg-white order-details-box letter-spacing"
      >
        <div class="card-head bg-white d-flex align-items-center">
          <i class="ph ph-airplane-in-flight text-success"></i>
          <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15">
            Shipping
          </h5>
        </div>
        <div class="card-body">
          <ul class="list ps-0 mb-0 list-unstyled">
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-employee"></i>
                Carrier:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                {{ orderDetails.shipping_service ? orderDetails.shipping_service.data.carrier_display_name : 'Not Selected' }}
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="flaticon-mail-2"></i>
                Service:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                {{ orderDetails.shipping_service ? orderDetails.shipping_service.data.display_name : 'Not Selected' }}
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <div
                class="title text-black fs-md-15 fs-lg-16 fw-semibold position-relative"
              >
                <i class="ph ph-sort-ascending"></i>
                Priority:
              </div>
              <span class="d-block text-paragraph fs-md-15 fs-lg-16">
                {{ orderDetails.shipping_priority_level }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-lg-8">
      <div
        class="card mb-25 border-0 rounded-0 bg-white order-details-box letter-spacing"
      >
        <div class="card-head bg-white d-flex align-items-center">
          <i class="ph ph-list-bullets text-primary"></i>
          <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15">
            Order Items
          </h5>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table text-nowrap align-middle mb-0">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 ps-0"
                  >
                    Product Name
                  </th>
                  <th
                    scope="col"
                    class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13"
                  >
                    Quantity
                  </th>
                </tr> 
              </thead>
              <tbody>

                <tr v-for="item in orderDetails?.order_items?.data" :key="item.id">
                  <th
                    class="shadow-none fw-medium text-black product-title ps-0"
                  >
                    <router-link
                      to="/product-details"
                      class="d-flex align-items-center text-decoration-none text-black fs-md-15 fs-lg-16"
                    >
                      {{ item.stock_product.data.name }}
                    </router-link>
                  </th>
                  <td class="shadow-none lh-1 fw-medium text-paragraph">{{ item.quantity }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div
        class="card mb-25 border-0 rounded-0 bg-white order-details-box letter-spacing"
      >
        <div class="card-head bg-white d-flex align-items-center">
          <i class="flaticon-document text-info"></i>
          <h5 class="mb-0 fw-bold text-black ms-10 ms-md-15">Order Summary</h5>
        </div>
        <div class="card-body">
          <ul class="order-summary-list ps-0 mb-0 list-unstyled">
            <li class="d-flex align-items-center justify-content-between">
              <span class="d-block text-muted text-uppercase fw-medium fs-13">
                DESCRIPTION
              </span>
              <span class="d-block text-muted text-uppercase fw-medium fs-13">
                PRICE
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <span class="d-block text-paragraph fw-medium">
                Shipping Paid
              </span>
              <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium">
                ${{ orderDetails.shipping_paid }}
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between" v-if="orderDetails.tax_paid">
              <span class="d-block text-paragraph fw-medium"> Tax Paid </span>
              <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium">
                ${{ orderDetails.tax_paid }}
              </span>
            </li>
            <li class="d-flex align-items-center justify-content-between">
              <span class="d-block text-paragraph fw-medium"> Amount Paid </span>
              <span class="d-block text-primary fs-md-15 fs-lg-16 fw-bold">
                ${{ orderDetails.amount_paid }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
<!--     <div class="col-lg-12 col-xxxl-6">
      <div
        class="card mb-25 border-0 rounded-0 bg-white order-details-box letter-spacing"
      >
        <div
          class="card-head bg-white d-flex justify-content-between align-items-center"
        >
          <h5 class="mb-0 fw-bold text-black">Track Order</h5>
          <span class="d-block fs-md-15 fs-lg-16 text-muted"
            >Tracking ID-
            <span class="text-primary fw-medium text-decoration-underline"
              >#1004216609</span
            ></span
          >
        </div>
        <div class="card-body">
          <ul class="track-order-list ps-0 list-unstyled mb-0">
            <li class="text-muted position-relative active">
              <div class="dot bg-white rounded-circle mb-10 mb-md-15"></div>
              <span class="d-block fs-md-15 fs-lg-16 fw-semibold mb-5"
                >Order Placed</span
              >
              <span class="d-block mb-1">22 Mar, 2023 -</span>
              <span class="d-block">11:35AM</span>
            </li>
            <li class="text-muted position-relative active">
              <div class="dot bg-white rounded-circle mb-10 mb-md-15"></div>
              <span class="d-block fs-md-15 fs-lg-16 fw-semibold mb-5"
                >Packed</span
              >
              <span class="d-block mb-1">23 Mar, 2023 -</span>
              <span class="d-block">10:35AM</span>
            </li>
            <li class="text-muted position-relative">
              <div class="dot bg-white rounded-circle mb-10 mb-md-15"></div>
              <span class="d-block fs-md-15 fs-lg-16 fw-bold text-black mb-5"
                >Shipped</span
              >
              <span class="d-block mb-1">23 Mar, 2023 -</span>
              <span class="d-block">3:45PM</span>
            </li>
            <li class="text-muted position-relative">
              <div class="dot bg-white rounded-circle mb-10 mb-md-15"></div>
              <span class="d-block fs-md-15 fs-lg-16 fw-bold text-black mb-5"
                >Delivered</span
              >
              <span class="d-block mb-1">23 Mar, 2023 -</span>
              <span class="d-block">3:45PM</span>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import apiConnector from '../../utils/apiConnector';
import showToast from '/src/utils/showToast';
import { Modal } from "bootstrap";
import $ from "jquery";

export default {
  name: 'OrderDetails',
  data() {
    return {
      orderDetails: {},
      shippingServices: [],
      inputShippingServiceSlug: null,
      inputShippingPriority: null,
      inputBypassAddressValidation: null,
      isLoading: true,
    };
  },
  mounted() {
    this.fetchOrderDetails();
  },
  methods: {
      async submitIssue() {
        this.isLoading = true; // Show the overlay and spinner

        // Prevent the default form submission
        event.preventDefault();

        // Collect form data
        const formData = {
          order_id: this.orderDetails.id,
          type: document.getElementById('issueType').value,
          description: document.getElementById('issueDescription').value,
          // items_applied: this.orderDetails.items.data
          //   .filter(item => document.querySelector(`input[data-item-id="${item.id}"]`).value > 0)
          //   .map(item => ({
          //     id: item.id,
          //     quantity: document.querySelector(`input[data-item-id="${item.id}"]`).value
          //   }))
        };

        try {
          const response = await apiConnector.makePostRequest(`issues`, formData);
          console.log(response.data);
          var link = document.getElementById('modalDismiss');
          link.click();
          showToast('Issue successfully submitted');

          // Handle success (e.g., show a message or redirect)
        } catch (error) {
          console.error(error);
          // Handle error (e.g., show an error message)
        } finally {
          this.isLoading = false; // Hide the overlay and spinner
        }
    },
    async fetchOrderDetails() {
      const orderId = this.$route.params.order_id;
      try {
        const response = await apiConnector.makeGetRequest(`orders/${orderId}?include=shipments,shipping_service`);
        this.orderDetails = response.data.data;
      } catch (error) {

        console.error('Error fetching order details:', error);
      }
      finally {
         this.isLoading = false; // Hide the overlay and spinner
      }
    },
    async handleHoldOrder(orderId) {
      this.isLoading = true;
      try {
        await holdOrder(orderId);
        showToast('Order held successfully');
        this.fetchOrderDetails();
      } catch (error) {
        console.error('Failed to hold order:', error);
        showToast('Failed to hold order', true);
      } finally {
        this.isLoading = false;
      }
    },
    async handleReleaseOrder(orderId) {
      this.isLoading = true;
      try {
        await releaseOrder(orderId);
        showToast('Order released successfully');
        this.fetchOrderDetails();
      } catch (error) {
        console.error('Failed to release order:', error);
        showToast('Failed to release order', true);
      } finally {
        this.isLoading = false;
      }
    },
    async openEditShippingModal() {
      this.isLoading = true;
      try {
        let editShippingModal = Modal.getOrCreateInstance($('#editShippingModal'));
        const response = await apiConnector.makeGetRequest(`orders/${this.orderDetails.id}/shipping-services-with-rates`);
        this.shippingServices = response.data.data;
        if(this.orderDetails.shipping_service){
          this.inputShippingServiceSlug = this.orderDetails.shipping_service.data.slug;
        }
        this.inputShippingPriority = this.orderDetails.shipping_priority || 0;
        this.inputBypassAddressValidation = this.orderDetails.bypass_address_validation || 0;
        editShippingModal.show();
      } catch (error) {
        console.error('Error fetching shipping services:', error);
      }
      finally {
         this.isLoading = false; // Hide the overlay and spinner
      }
    },
    async updateShippingOptions() {
      this.isLoading = true;
      try {
       let editShippingModal = Modal.getInstance($('#editShippingModal'));
        if(editShippingModal){
          const response = await apiConnector.makePatchRequest(`orders/${this.orderDetails.id}?include=shipping_service`, {
            'shipping_service_slug': this.inputShippingServiceSlug,
            'shipping_priority': this.inputShippingPriority,
            'bypass_address_validation': this.inputBypassAddressValidation
          });
          this.orderDetails = response.data.data;
          showToast('Order successfully updated!');
          editShippingModal.hide();
        }
      } catch (error) {
        console.error('Error updating order:', error);
      }
      finally {
         this.isLoading = false; // Hide the overlay and spinner
      }
    },
    formatDate(dateString) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return new Date(dateString).toLocaleDateString(undefined, options);
    },
  },
};
</script>
