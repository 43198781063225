<template>
  <div>
    <BreadCrumb PageTitle="Orders List" />
    <OrdersList />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import OrdersList from "../../components/Orders/OrdersList.vue";

export default defineComponent({
  name: "OrdersListPage",
  components: {
    BreadCrumb,
    OrdersList,
  },
});
</script>