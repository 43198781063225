<template>
  <div v-if="isFullScreenLoading" class="full-overlay">
    <span class="spinner-border text-light spinner-overlay" role="status" aria-hidden="true"></span>
  </div>
  <div class="modal fade" id="editShippingModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog" v-if="selectedOrders.length">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Edit shipping options for the {{ selectedOrders.length }} selected order{{ selectedOrders.length > 1 ? 's' : '' }} {{ selectedOrders.length == 1 ? 'ID' : 'IDs' }}: {{ selectedOrders.join(', ')}}</h1>
          <button type="button" class="btn-close" id="modalDismiss" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form v-on:submit.prevent="updateOrdersShippingOptions">
          <div class="modal-body">
            <div class="mb-mb-15 mb-md-20">
              <label for="shippingServicesSelect" class="form-label fw-medium">Shipping Service:</label>
              <select id="shippingServicesSelect" v-model="inputShippingServiceSlug" class="form-select shadow-none text-black fs-md-15" required>
                <option v-for="shippingService in shippingServices" :key="shippingService.id" :value="shippingService.slug">{{ shippingService.display_name }}{{ shippingService.shipping_rate && shippingService.shipping_rate.delivery_days ? ': '+shippingService.shipping_rate.delivery_days + ' Days' + (shippingService.shipping_rate.delivery_date_guaranteed ? ' Guaranteed' : '') : (shippingService.etd ? ': '+shippingService.etd : '') }} {{ shippingService.shipping_rate ? '($' + shippingService.shipping_rate.rate + ')' : '' }}</option>
              </select>
            </div>
            <div class="mb-15 mb-md-20">
              <label for="shippingPriorityLevelSelect" class="form-label fw-medium">Handling Priority:</label>
              <select id="shippingPriorityLevelSelect" v-model="inputShippingPriority" class="form-select shadow-none text-black fs-md-15" required>
                <option value="0" :key="0">Normal</option>
                <option value="1" :key="1">High</option>
                <option value="2" :key="2">Urgent</option>
              </select>
            </div>
<!--             <div class="mb-15 mb-md-20">
              <input class="form-check-input shadow-none" id="bypassAddressValidationSelect" type="checkbox" v-model="inputBypassAddressValidation" :checked="inputBypassAddressValidation ? true : false" value="1" /> <label for="bypassAddressValidationSelect" class="form-check-label fw-medium position-relative top-1">Bypass address validation</label>
            </div> -->
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-primary" :disabled="isLoading">Update Orders</button>
          </div>
        </form>
      </div>

    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-head box-shadow bg-white">
      <!-- <div class="col d-md-flex align-items-center justify-content-between p-15 p-sm-20">
        <div class="row list justify-content-center" v-if="stats.length > 0">
          <div
            class="col-3 col-md-3 cursor-pointer"
            v-for="stat in stats"
            :key="stat.id"
            @click="navigateToOrdersList(stat.name)"
            :class="['column', { 'col-md-6': stats.length > 4 }]"
            :style="{ width: columnWidth }"
          >
            <div :class="['p-15 mt-15', 'bg-f3f7f9']">
              <span
                class="d-block mb-6 fw-medium text-black-emphasis fs-13 text-uppercase"
              >
                {{ stat.name }}
              </span>
              <h4 :class="['mb-0 fw-black', stat.class]">
                {{ stat.total_orders }}
              </h4>
            </div>
          </div>
        </div>
        <div v-if="!stats.length" style="width: 100%;display: flex;justify-content: center;align-items: center;">
          <span class="spinner-border" style="width: 1.5rem; height: 1.5rem;color: #ccc;" role="status" aria-hidden="true"></span>
        </div>
      </div> -->
      <div class="row px-3 py-2">
        <div class="mt-20 col-12 col-md-4">
          <form class="search-box position-relative">
            <input
              type="text"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Search order"
              v-model="searchInput"
            />
            <button
              class="bg-transparent text-primary transition p-0 border-0"
              disabled
            >
              <i class="flaticon-search-interface-symbol"></i>
            </button>
          </form>
        </div>
        <div class="col row">
          <div class="col">
            <form>
              <label for="from-date" class="fw-medium">From</label>
              <input type="date" id="from-date" v-model="fromDate" class="form-control shadow-none text-black fs-md-15">
            </form>
          </div>
          <div class="col">
            <form>
              <label for="to-date" class="fw-medium">To</label>
              <input type="date" id="to-date" v-model="toDate" class="form-control shadow-none text-black fs-md-15">
            </form>
          </div>
          <div class="col">
            <form>
              <label for="status-select" class="fw-medium">Status </label>
              <select class="form-select shadow-none text-black fs-md-15" id="status-select" v-model="fulfillmentStatus">
                <option selected value="all" >All</option>
                <option v-for="fulfillmentStatusOption in fulfillmentStatuses" :key="fulfillmentStatusOption.id">{{ fulfillmentStatusOption.name }}</option>
              </select>
            </form>
          </div>
          <div class="col" v-if="selectedOrders.length">
            <form v-on:submit.prevent="openEditShippingModal">
              <label for="status-select" class="fw-medium">{{ selectedOrders.length == 1 ? '1 order' : selectedOrders.length + ' orders' }}</label>
              <button class="form-control btn btn-secondary">Bulk edit shipping</button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Existing template code -->
    <div class="card-body p-15 p-sm-20 p-md-25 position-relative">
      <div v-if="isLoading" class="relative-overlay">
        <span class="spinner-border text-light spinner-overlay" role="status" aria-hidden="true"></span>
      </div>
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                <input @click="selectAllAvailableOrders()" v-model="selectedAll" :indeterminate.prop="someOrdersSelectedNotAll" type="checkbox" id="orders-select-all" name="orders-select-all" class="form-check-input shadow-none" v-if="orders.length">
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                ID
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                <SortableColumn column="order_number" :sortedBy="sortField" @sortClicked="sortClicked">Order #</SortableColumn>
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Fulfillment Status
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Status Note
              </th>

              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                <SortableColumn column="customer_name" :sortedBy="sortField" defaultOrder='asc' @sortClicked="sortClicked">Customer Name</SortableColumn>
              </th>

              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Email
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                City
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                <SortableColumn column="channel_name" :sortedBy="sortField" defaultOrder='asc' @sortClicked="sortClicked">Channel</SortableColumn>
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                <SortableColumn column="ordered_at" :sortedBy="sortField" @sortClicked="sortClicked">Ordered at</SortableColumn>
<!--                 <a href="#" v-on:click="orderBy('ordered_at')" :class="sortingActiveClass('ordered_at')+' icon-link text-decoration-none'">
                  Ordered at <i :class="sortingOrderClass('ordered_at')+' lh-1 position-relative top-1'"></i>
                </a> -->
              </th>
            </tr>
          </thead>

          <tbody v-if="orders.length">
            <tr v-for="order in orders" :key="order.id">
              <td class="ps-0">
                <input type="checkbox" name="orders_selected[]"  @click="selectCheckboxOrder(order)" :value="order.id" class="form-check-input shadow-none" v-model="selectedOrders" v-if="this.orderIsSelectable(order)">
              </td>
              <td><router-link :to="{ name: 'OrderDetailsPage', params: { order_id: order.id } }">{{ order.id }}</router-link></td>
              <td>{{ order.order_number }}</td>
              <td>{{ order.fulfillment_status }}</td>
              <td>{{ order.fulfillment_note ? order.fulfillment_note  : '-'}}</td>
              <td>{{ order.customer_first_name }} {{ order.customer_last_name }}</td>
              <td>{{ order.customer_email }}</td>
              <td>{{ order.customer_address_city }}</td>
              <td>{{ order.channel_name }}</td>
              <td>{{ formatTimestamp(order.ordered_at) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <PaginationFooter :pagination="meta.pagination" :sortField="sortField" :sortOrder="sortOrder" :search="searchInput" />
    </div>
  </div>
</template>

<script>
import { currentPage, formatTimestamp, getTodayDateSqlFormat, get7DaysAgoDateSqlFormat } from "../../utils/helper";
import SortableColumn from "../Common/SortableColumn.vue";
import PaginationFooter from "../Common/PaginationFooter.vue";
import apiConnector from '../../utils/apiConnector';
import showToast from '/src/utils/showToast';
import { Modal } from "bootstrap";
import $ from "jquery";

export default {
  name: "OrdersList",
  components: {
    SortableColumn,
    PaginationFooter
  },
  data() {
    return {
      isLoading: false,
      isFullScreenLoading: false,
      sortField: 'ordered_at',
      sortOrder: 'desc',
      // stats: [],
      fromDate: get7DaysAgoDateSqlFormat(),
      toDate: getTodayDateSqlFormat(),
      fulfillmentStatus: null,
      selectedOrders: [],
      selectedAll: false,
      orders: [],
      fulfillmentStatuses: [
        {id: 1, name: 'Pending'},
        {id: 2, name: 'Picking'},
        {id: 3, name: 'QC Complete'},
        {id: 5, name: 'Shipped'},
        {id: 6, name: 'On Hold'},
        {id: 7, name: 'Cancelled'},
        {id: 8, name: 'Backordered'},
        {id: 9, name: 'Packed'},
        {id: 10, name: 'Pending PickUp'},
        {id: 12, name: 'Partially Shipped'},
      ],
      selectableFulfillmentStatuses: ['Pending', 'Pickable', 'On Hold', 'Backordered'],
      searchInput: '',
      meta: {
        pagination: {
          total: 0,
          count: 0,
          per_page: 20,
          current_page: 1,
          total_pages: 1,
          links: {
            next: null,
            previous: null,
          },
        },
      },
      someOrdersSelectedNotAll: false,
      shippingServices: [],
      inputShippingServiceSlug: null,
      inputShippingPriority: 0,
      inputBypassAddressValidation: 0
    };
  },
  watch: {
    // Watch the entire route for changes in query parameters
    '$route.query': {
      immediate: true,
      handler(newQuery) {
        this.selectedAll = false;
        this.sortField = newQuery.sort_by || 'ordered_at';
        this.sortOrder = newQuery.sort_order || 'desc';
        this.searchInput = newQuery.q || '';
        this.fromDate = newQuery.from || get7DaysAgoDateSqlFormat();
        this.toDate = newQuery.to || getTodayDateSqlFormat();
        this.fulfillmentStatus = newQuery.status || 'all';
        this.someOrdersSelectedNotAll = false;
        this.fetchOrders();
      },
    },
    searchInput(search){
      this.updatePageUrl();
    },
    fromDate(date){
      this.updatePageUrl();
    },
    toDate(date){
      this.updatePageUrl();
    },
    fulfillmentStatus(status){
      this.updatePageUrl();
    }
  },
  computed: {
    // columnWidth() {
    //   const screenWidth = window.innerWidth;
    //   if (screenWidth >= 992) {
    //     // For larger screens, calculate the width based on the number of stats
    //     if (!this.stats.length) return '0%'; // Handle the case where there are no stats
    //     const percentage = 100 / this.stats.length;
    //     return `${percentage}%`;
    //   } else {
    //     // For smaller screens, let CSS handle the layout
    //     return ''; // Return an empty string to not apply inline width
    //   }
    // }
    // someSelected() {
    //   return this.selectedOrders.length == this.orders
    // }
  },
  mounted() {
    // this.getOrderStats();

    // this.orderStatsInterval = setInterval(() => {
    //   this.getOrderStats();
    // }, 60000);
  },
  beforeUnmount() {
    // clearInterval(this.orderStatsInterval);
  },
  methods: {
    async fetchOrders() {
  
      this.isLoading = true;

      let url = `/orders?excludes=order_items,items&page=${currentPage()}&sorting[${this.sortField}]=${this.sortOrder}&filter[from]=${this.fromDate}&filter[to]=${this.toDate}`;

      if (this.fulfillmentStatus != 'all') url += `&filter[fulfillment_status]=${encodeURIComponent(this.fulfillmentStatus)}`;
      if (this.searchInput) url += `&q=${encodeURIComponent(this.searchInput)}`;

      try {
        const response = await apiConnector.makeGetRequest(url);
        
        this.orders = response.data.data;
        this.meta = response.data.meta;
        this.selectedAll = this.allPageSelectableOrdersSelected();
        this.someOrdersSelectedNotAll = this.someOrdersSelectedNotAllPage();

        // this.$router.replace({ path: this.$route.path, query: { q, page, 'filter[fulfillment_status]': fulfillmentStatus, sorting: { [this.sortField]: this.sortOrder } } }).catch(err => { console.log(err); });

      } catch (error) {
        console.error('There was an error fetching the orders:', error);
        this.orders = [];
        this.meta = { pagination: { total: 0, count: 0, current_page: 1, total_pages: 1, links: { next: null, previous: null } } };
      } finally {
        this.isLoading = false;
      }
    },
    // async getOrderStats() {
    //   try {
    //     const response = await apiConnector.makeGetRequest('/orders/stats');
    //     const stats = response.data.data;
    //     const classes = ['text-primary','text-danger','text-success','text-info'];
    //     let class_key = 0;
    //     for (var i = stats.length - 1; i >= 0; i--) {
    //       class_key = i % 4;
    //       stats[i].class = classes[class_key];
    //     }
    //     this.stats = stats;
    //   } catch (error) {
    //     console.error('There was an error fetching the orders:', error);
    //     this.orders = [];
    //     this.meta = { pagination: { ...this.meta.pagination, total: 0, count: 0, current_page: 1, total_pages: 1, links: { next: null, previous: null } } };
    //   }
    // },

    sortClicked(sorting) {
      this.sortField = sorting.column;
      this.sortOrder = sorting.order;
      this.updatePageUrl();
    },

    updatePageUrl(page) {
      let query = {};
      if(page > 1){
        query.page = page;
      }
      if(this.sortField != 'ordered_at' || this.sortOrder != 'desc'){
        query.sort_by = this.sortField;
        query.sort_order = this.sortOrder;
      }
      if(this.fromDate != get7DaysAgoDateSqlFormat()){
        query.from = this.fromDate;
      }
      if(this.toDate != getTodayDateSqlFormat()){
        query.to = this.toDate;
      }
      if(this.fulfillmentStatus != 'all'){
        query.status = this.fulfillmentStatus;
      }
      if(this.searchInput){
        query.q = this.searchInput;
      }
      // console.log({ name: 'OrdersListPage', query: query });
      this.$router.replace({ name: 'OrdersListPage', query: query }).catch(err => { console.log(err) });
    },

    selectAllAvailableOrders() {
      if(this.selectedAll){
        for (var i = 0; i < this.orders.length; i++) {
          let index = this.selectedOrders.indexOf(this.orders[i].id);
          if(index >= 0){
            this.selectedOrders.splice(index, 1);
          }
        }
      }else{
        for (var j = 0; j < this.orders.length; j++) {
          if(this.orderIsSelectable(this.orders[j]) && !this.selectedOrders.includes(this.orders[j].id)){
            this.selectedOrders.push(this.orders[j].id);
          }
        }
      }
      this.someOrdersSelectedNotAll = false;
    },
    selectCheckboxOrder(order) {
      let index = this.selectedOrders.indexOf(order.id);
      if(index >= 0){
        this.selectedOrders.splice(index, 1);
      }else if(this.orderIsSelectable(order)){
        this.selectedOrders.push(order.id);
      }

      let allSelected = true;
      for (var j = 0; j < this.orders.length; j++) {
        if(this.orderIsSelectable(this.orders[j]) && !this.selectedOrders.includes(this.orders[j].id)){
          allSelected = false
        }
      }
      this.selectedAll = this.allPageSelectableOrdersSelected();
      this.someOrdersSelectedNotAll = this.someOrdersSelectedNotAllPage();
    },

    allPageSelectableOrdersSelected(){
      for (var j = 0; j < this.orders.length; j++) {
        if(this.orderIsSelectable(this.orders[j]) && !this.selectedOrders.includes(this.orders[j].id)){
          return false
        }
      }
      return true;
    },

    someOrdersSelectedNotAllPage(){
      let selectedCount = 0;
      let selectableCount = 0;
      for (var j = 0; j < this.orders.length; j++) {
        if(this.orderIsSelectable(this.orders[j])){
          selectableCount++;
          if(this.selectedOrders.includes(this.orders[j].id)){
            selectedCount++;
          }
        }
      }
      return this.selectedOrders.length && selectedCount < selectableCount;
    },

    orderIsSelectable(order){
      return this.selectableFulfillmentStatuses.includes(order.fulfillment_status) && order.customer_address_country_code == 'US';
    },

    async openEditShippingModal() {
      this.isFullScreenLoading = true;
      try {
        let editShippingModal = Modal.getOrCreateInstance($('#editShippingModal'));
        const response = await apiConnector.makeGetRequest(`shipping_services?filter[domestic]=1&include=shipping_packages,shipping_carrier.shipping_provider`);
        this.shippingServices = response.data.data;
        this.inputShippingPriority = 0;
        this.inputBypassAddressValidation = 0;
        editShippingModal.show();
      } catch (error) {
        console.error('Error fetching shipping services:', error);
      }
      finally {
         this.isFullScreenLoading = false; // Hide the overlay and spinner
      }
    },

    async updateOrdersShippingOptions() {
      this.isFullScreenLoading = true;
      try {
        let editShippingModal = Modal.getInstance($('#editShippingModal'));
        if(editShippingModal){
          const response = await apiConnector.makePatchRequest(`orders/update-shipping-options`, {
            'ids': this.selectedOrders,
            'shipping_service_slug': this.inputShippingServiceSlug,
            'shipping_priority': this.inputShippingPriority
          });
          const responseData = response.data.data;
          if(responseData.notAppliedUpdateOrderIds.length){
            for (var i = 0; i < responseData.notAppliedUpdateOrderIds.length; i++) {
              showToast('Order '+responseData.notAppliedUpdateOrderIds[i]+' failed to update', true);
            }
            for (var j = 0; j < responseData.appliedUpdateOrderIds.length; j++) {
              showToast('Order '+responseData.appliedUpdateOrderIds[j]+' successfully updated!');
            }
          }else{
            showToast(responseData.appliedUpdateOrderIds.length+' Orders successfully updated!');
          }
          this.selectedOrders = [];
          this.selectedAll = false;
          this.someOrdersSelectedNotAll = false;
          editShippingModal.hide();
        }
      } catch (error) {
        console.error('Error updating order:', error);
      }
      finally {
         this.isFullScreenLoading = false; // Hide the overlay and spinner
      }
    },

    // async updateOrdersShippingOptions() {
    //   this.isFullScreenLoading = true;
    //   let editShippingModal = Modal.getInstance($('#editShippingModal'));
    //   try{
    //     if(editShippingModal){
    //       for (var i = 0; i < this.selectedOrders.length; i++) {
    //         try {
    //           await apiConnector.makePatchRequest(`orders/${this.selectedOrders[i]}?include=shipping_service`, {
    //             'shipping_service_slug': this.inputShippingServiceSlug,
    //             'shipping_priority': this.inputShippingPriority
    //           });
    //           showToast('Order '+this.selectedOrders[i]+' successfully updated!');
    //         } catch (error) {
    //           showToast('Error Updating Order '+this.selectedOrders[i], true);
    //           console.error('Error updating order '+this.selectedOrders[i], error);
    //         }
    //       }
    //       this.selectedOrders = [];
    //       editShippingModal.hide();
    //     }
    //   }finally {
    //     this.isFullScreenLoading = false; // Hide the overlay and spinner
    //   }
    // },

    // navigateToOrdersList(statId) {
    //   this.$router.push({
    //     name: 'OrdersListPage',
    //     query: { 'filter[fulfillment_status]': statId, sorting: { [this.sortField]: this.sortOrder } }
    //   }).catch(err => { console.log(err) });
    // },
    formatTimestamp
  },
};
</script>
