<template>
  <BreadCrumb PageTitle="Teams" />
  <KanbanContent />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../template-components/Common/BreadCrumb.vue";
import KanbanContent from "../template-components/Kanban/KanbanContent.vue";

export default defineComponent({
  name: "KanbanPage",
  components: {
    BreadCrumb,
    KanbanContent,
  },
});
</script>