import { createWebHistory, createRouter } from "vue-router";


import LoginPage from "../pages/Authentication/LoginPage.vue";
import ForgotPasswordPage from "../pages/Authentication/ForgotPasswordPage.vue";
import ResetPasswordPage from "../pages/Authentication/ResetPasswordPage.vue";
import IssuesListPage from "../pages/Orders/IssuesListPage.vue";
import InventoryPage from "../pages/Inventory/InventoryPage.vue";
import InventoryReportsPage from "../pages/Inventory/InventoryReportsPage.vue";
import SoldVsBackordersPage from "../pages/Inventory/SoldVsBackordersPage.vue";
import OrdersListPage from "../pages/Orders/OrdersListPage.vue";
import OrderDetailsPage from "../pages/Orders/OrderDetailsPage.vue";
import ProductsListPage from "../pages/Products/ProductsListPage.vue";
import AddOrEditProductPage from "../pages/Products/AddOrEditProductPage.vue";
import StockProductsListPage from "../pages/StockProducts/StockProductsListPage.vue";
import AddOrEditStockProductPage from "../pages/StockProducts/AddOrEditStockProductPage.vue";
import ReturnOrdersListPage from "../pages/ReturnOrdersListPage.vue";
import SpecialProjectsListPage from "../pages/SpecialProjects/SpecialProjectsListPage.vue";
import AddOrEditSpecialProjectPage from "../pages/SpecialProjects/AddOrEditSpecialProjectPage.vue";
import ErrorPage from "../pages/ErrorPage.vue";

import templateRoutes from './template-routes';
import apiConnector from '../utils/apiConnector';

const appRoutes = [
  {
    path: '/',
    redirect: to => {
      // Check authorization from query param
      if(to.query.shipbreezeAuthorized == 'true'){
        localStorage.setItem('shipbreezeAuthorized', 'true');
      }
      return { path: '/orders', query: {} }
    }
  },
  {
    path: "/orders",
    name: "OrdersListPage",
    component: OrdersListPage,
  },
  {
    path: "/orders/:order_id",
    name: "OrderDetailsPage",
    component: OrderDetailsPage
  },
  {
    path: "/issues",
    name: "IssuesListPage",
    component: IssuesListPage
  },
  {
    path: "/returns",
    name: "ReturnOrdersPage",
    component: ReturnOrdersListPage
  },
  {
    path: "/inventory",
    name: "InventoryPage",
    component: InventoryPage
  },
  {
    path: "/inventory/reports",
    name: "InventoryReportsPage",
    component: InventoryReportsPage
  },
  {
    path: "/inventory/sold-vs-backorders",
    name: "SoldVsBackordersPage",
    component: SoldVsBackordersPage
  },
  {
    path: "/products",
    name: "ProductsListPage",
    component: ProductsListPage
  },
  {
    path: "/new-product",
    name: "AddProductsPage",
    component: AddOrEditProductPage
  },
  {
    path: "/products/:uid",
    name: "EditProductPage",
    component: AddOrEditProductPage
  },
  {
    path: "/stock-products",
    name: "StockProductsListPage",
    component: StockProductsListPage
  },
  {
    path: "/stock-products/:id",
    name: "EditStockProductPage",
    component: AddOrEditStockProductPage
  },
  {
    path: "/special-projects",
    name: "SpecialProjectsListPage",
    component: SpecialProjectsListPage
  },
  {
    path: "/new-special-project",
    name: "AddSpecialProjectPage",
    component: AddOrEditSpecialProjectPage
  },
  {
    path: "/special-projects/:id",
    name: "EditSpecialProjectPage",
    component: AddOrEditSpecialProjectPage
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
    meta: { requiresNoSidebar: true, public: true },
  },
  {
    path: "/logout",
    redirect: () => {
      apiConnector.logout();
      localStorage.removeItem('shipbreezeAuthorized');
      return { path: '/login' }
    }
  },
  {
    path: "/forgot-password",
    name: "ForgotPasswordPage",
    component: ForgotPasswordPage,
    meta: { requiresNoSidebar: true, public: true },
  },
  {
    path: "/reset-password/:token",
    name: "ResetPasswordPage",
    component: ResetPasswordPage,
    meta: { requiresNoSidebar: true, public: true }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "ErrorPage",
    component: ErrorPage
  },
];

const routes = [...templateRoutes, ...appRoutes];

const router = createRouter({
  history: createWebHistory(),
  linkExactActiveClass: "active",
  routes: routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public);
  const isAuthorized = localStorage.getItem('shipbreezeAuthorized');

  // If the route is public or there's Authorization in localStorage
  if (isPublic || isAuthorized) {
    next();
  }else{ // Otherwise redirect to login
    next('/login');
  }
});

export default router;