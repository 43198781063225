<template>
  <BreadCrumb PageTitle="Collapse" />
  <CollapseContent />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BreadCrumb from "../../template-components/Common/BreadCrumb.vue";
import CollapseContent from "../../template-components/UIElements/Collapse/CollapseContent.vue";

export default defineComponent({
  name: "CollapsePage",
  components: {
    BreadCrumb,
    CollapseContent,
  },
});
</script>