<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-product-box">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <form v-on:submit.prevent="submitCreateOrUpdateProduct">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10"
                >Product Name</label
              >
              <input
                type="text"
                name="productName"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="e.g. Branded Smart Watch"
                v-model="productName"
                v-on:blur="validateProductName"
              />
              <div class="invalid-feedback" id='productNameError'></div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                SKU
              </label>
              <input
                type="text"
                name="productSku"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="e.g. BRAND-S-WATCH"
                v-model="productSku"
                v-on:blur="validateSku"
              />
              <div class="invalid-feedback" id='productSkuError'></div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Select Type
              </label>
              <select class="form-select shadow-none fw-semibold rounded-0"
                name="productType"
                v-model="productType"
                v-on:blur="validateProductType"
                v-on:change="validateProductType"
              >
                <option :value="null" disabled>-- Product Type --</option>
                <option v-for="typeObj in productTypes" :value="typeObj.type" :key="typeObj.type">{{ typeObj.name }}</option>
              </select>
              <div class="invalid-feedback" id='productTypeError'></div>
            </div>
          </div>
          <div class="col-md-12" v-if="productType == 'simple'">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10"
                >UPC</label
              >
              <input
                type="text"
                name="productUpc"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="e.g. 850003376356"
                v-model="productUpc"
                v-on:blur="validateUpc"
              />
              <div class="invalid-feedback" id='productUpcError'></div>
            </div>
          </div>
          <div class="col-md-12 mb-25" v-if="productType == 'bundle'">
            <div class="d-flex mb-15 justify-content-between">
              <h5 class="card-title fw-bold">
                Components
              </h5>
              <button type="button" class="btn btn-outline-primary btn-sm position-relative" data-bs-toggle="modal" data-bs-target="#addComponentModal" v-on:click="addComponentClicked()">Add Product Component</button>
            </div>
            <ul class="list-group">
              <li class="list-group-item list-group-item-warning fw-medium fs-md-15" v-if="components.length == 0">Please add product components to the bundle.</li>
              <li class="list-group-item fw-medium fs-md-15 d-flex justify-content-between" v-for="component in components" :key="component.uid">
                <div class="d-flex flex-column">
                  <p class="mb-1 fs-md-15 fs-lg-16">{{ component.quantity }} x {{ component.name }}</p>
                  <small>SKU: {{ component.sku }}</small>
                  <small>UPC: {{ component.upc }}</small>
                </div>
                <div class="dropdown">
                  <button
                    class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition fs-20 no-after text-paragraph"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        data-bs-toggle="modal" data-bs-target="#addComponentModal"
                        class="dropdown-item d-flex align-items-center"
                        v-on:click="editProductComponent(component.uid)"
                        ><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                        Edit
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center cursor-pointer"
                        v-on:click="deleteProductComponent(component.uid)"
                        ><i
                          class="flaticon-delete lh-1 me-8 position-relative top-1"
                        ></i>
                        Delete</a
                      >
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-md-12">
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
              {{ product ? 'Update' : 'Create' }} Product
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="addComponentModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ updateComponentUid ? 'Edit' : 'Add' }} Product Component</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-2">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10"
                  >Quantity</label
                >
                <input
                  type="number"
                  step="1"
                  class="form-control shadow-none rounded-0 text-black"
                  v-model="newComponentQty"
                />
              </div>
            </div>
            <div class="col-md-10">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10"
                  >Product</label
                >
                <model-select
                  class="form-control shadow-none rounded-0 text-black"
                  placeholder="e.g. Black Wristband"
                  name="newComponentUid"
                  v-model="newComponentUid"
                  :options="newComponentOptions"
                  @searchchange="updateProductSearch"
                >
              </model-select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" v-on:click="addOrUpdateProductComponent" class="btn btn-outline-primary">{{ updateComponentUid ? 'Update' : 'Add'}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import "vue-search-select/dist/VueSearchSelect.css"
import { defineComponent } from "vue";
import { ModelSelect } from "vue-search-select";
import showToast from '../../utils/showToast';
import apiConnector from '../../utils/apiConnector';
import router from "../../router";
import { Modal } from "bootstrap";
import $ from "jquery";

export default defineComponent({
  name: "AddOrEditProduct",
  emits: ["pageUpdate"],
  components: {
    ModelSelect
  },
  setup: () => {
    const productTypes = [
      {
        type: "simple",
        name: "Simple"
      },
      {
        type: "bundle",
        name: "Bundle"
      },
      {
        type: "rush_handling",
        name: "Rush Handling"
      },
      {
        type: "insurance",
        name: "Insurance"
      },
      {
        type: "ignore",
        name: "Ignore"
      },
      {
        type: "gift_card",
        name: "Gift Card"
      },
      {
        type: "gift_message",
        name: "Gift Message"
      }
    ];
    return { productTypes };
  },
  mounted(){
    if(this.$route.name == "EditProductPage"){
      this.getEditProduct();
    }
  },
  data() {
    const query = this.$route.query;
    this.$router.replace({'query': undefined});
    return {
      product: null as any,
      productName: query.name ?? '',
      productSku: query.sku ?? '',
      productType: null,
      productUpc: '',
      components: [] as Array<any>,
      newComponentQty: 1,
      newComponentUid: null,
      returnedProducts: [] as Array<any>,
      newComponentOptions: [] as Array<any>,
      newComponentProductSearch: '',
      updateComponentUid: null
    }
  },
  methods: {
    async searchNotBundleProducts(search=null) {
      const response = await apiConnector.makeGetRequest(`/products?not_type=bundle&search=${search}`);
      this.returnedProducts = response.data.data;
      this.newComponentOptions = this.returnedProducts.map(product => { return {
        text: product.name + ' / ' + product.sku + (product.upc ? ' / ' + product.upc : ''),
        value: product.uid
      }});
    },
    async getEditProduct() {
      if(!this.$route.params.uid){
        return false;
      }
      const response = await apiConnector.makeGetRequest('/products/'+this.$route.params.uid);
      this.product = response.data.data;
      this.productName = this.product.name;
      this.productSku = this.product.sku;
      this.productType = this.product.type;
      this.productUpc = this.product.upc;
      this.components = this.product.components.data;
    },
    async submitCreateOrUpdateProduct() {
      if(!this.validates()){
        return false;
      }
      const payload = this.buildProductPayload();
      let response = null as any;
      if(this.$route.name == "EditProductPage"){
        if(!this.product){
          return false;
        }
        response = await apiConnector.makePatchRequest(`/products/${this.product.uid}`, payload);
      }else{
        response = await apiConnector.makePostRequest('/products', payload);
      }
      if(response && response.data && response.data.data){
        let action = this.$route.name == "EditProductPage" ? 'Updated' : 'Created';
        router.push({path: '/products/'+response.data.data.uid});
        showToast(`Product Successfully ${action}`);
        if(this.$route.name != "EditProductPage"){
          this.product = response.data.data;
          this.$emit('pageUpdate', 'EditProductPage');
        }
      }
    },
    buildProductPayload(){
      let payload = {
        name: this.productName,
        sku: this.productSku,
        type: this.productType
      };
      if(payload.type == 'simple'){
        payload['upc'] = this.productUpc;
      }else if(payload.type == 'bundle'){
        payload['components'] = this.components.map(component => component.uid);
        payload['component_quantities'] = this.components.map(component => component.quantity);
      }
      return payload;
    },
    validates(){
      let nameValidation = this.validateProductName();
      let skuValidation = this.validateSku();
      let typeValidation = this.validateProductType();
      let upcValidation = this.validateUpc();
      let componentsValidation = this.validateComponents();
      return nameValidation && skuValidation && typeValidation && upcValidation && componentsValidation;
    },
    validateProductName(){
      if(!this.productName || this.productName.length < 3){
        $('input[name=productName]').addClass('is-invalid');
        let message = !this.productName ? 'Product name is required' : 'Product name needs to be at least 3 chars long';
        $('#productNameError').html(message);
        return false
      }
      $('input[name=productName]').removeClass('is-invalid');
      $('#productNameError').html('');
      return true;
    },
    validateProductType(){
      if(!this.productType){
        $('select[name=productType]').addClass('is-invalid');
        let message = 'Product type is required';
        $('#productTypeError').html(message);
        return false
      }
      $('select[name=productType]').removeClass('is-invalid');
      $('#productTypeError').html('');
      return true;
    },
    validateSku(){
      if(!this.productSku || this.productSku.length < 3){
        $('input[name=productSku]').addClass('is-invalid');
        let message = !this.productSku ? 'Product SKU is required' : 'Product SKU needs to be at least 3 chars long';
        $('#productSkuError').html(message);
        return false
      }
      $('input[name=productSku]').removeClass('is-invalid');
      $('#productSkuError').html('');
      return true;
    },
    validateUpc(){
      if(this.productType == 'simple' && (!this.productUpc || this.productUpc.length < 3)){
        $('input[name=productUpc]').addClass('is-invalid');
        let message = !this.productUpc ? 'Product UPC is required for simple product' : 'Product UPC needs to be at least 3 digits long';
        $('#productUpcError').html(message);
        return false
      }
      $('input[name=productUpc]').removeClass('is-invalid');
      $('#productUpcError').html('');
      return true;
    },
    validateComponents(){
      const missingComponentsMsg = $('.list-group-item-warning');
      if(this.productType == 'bundle' && (this.components.length == 0)){
        missingComponentsMsg.addClass('list-group-item-danger').removeClass('list-group-item-warning');
        missingComponentsMsg.append(' (Product components are required for bundles)');
        return false
      }
      missingComponentsMsg.addClass('list-group-item-warning').removeClass('list-group-item-danger');
      $('#productUpcError').html('');
      return true;
    },
    addComponentClicked(){
      this.newComponentQty = 1;
      this.newComponentProductSearch = '';
      this.newComponentUid = null;
      this.returnedProducts = [];
      this.newComponentOptions = [];
      this.updateComponentUid = null;
    },
    addOrUpdateProductComponent(){
      let selectedProduct = this.returnedProducts.filter(product => product.uid == this.newComponentUid);
      selectedProduct = selectedProduct[0];
      selectedProduct['quantity'] = this.newComponentQty;
      if(!this.updateComponentUid){
        this.components.push(selectedProduct);
      }else{
        for(let i=0; i < this.components.length; i++){
          if(this.components[i].uid == this.updateComponentUid){
            this.components[i] = selectedProduct;
            break;
          }
        }
      }
      let addComponentModal = Modal.getInstance($('#addComponentModal'));
      if(addComponentModal){
        addComponentModal.hide();
      }
      this.newComponentQty = 1;
      this.newComponentProductSearch = '';
      this.newComponentUid = null;
      this.returnedProducts = [];
      this.newComponentOptions = [];
      this.updateComponentUid = null;
    },
    deleteProductComponent(uid){
      for(let i=0; i < this.components.length; i++){
        if(this.components[i].uid == uid){
          this.components.splice(i, 1);
          break;
        }
      }
    },
    editProductComponent(uid){
      for(let i=0; i < this.components.length; i++){
        if(this.components[i].uid == uid){
          let editComponent = this.components[i];
          this.newComponentQty = editComponent.quantity;
          this.updateProductSearch(editComponent.sku);
          this.newComponentUid = editComponent.uid;
          this.returnedProducts = [];
          this.newComponentOptions = [];
          this.updateComponentUid = editComponent.uid;
          break;
        }
      }
    },
    updateProductSearch(productSearch){
      if(productSearch){
        this.newComponentProductSearch = productSearch;
        this.searchNotBundleProducts(productSearch);
      }
    }
  },
});
</script>