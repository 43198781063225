import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb")!
  const _component_AddOrEditSpecialProject = _resolveComponent("AddOrEditSpecialProject")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BreadCrumb, {
      PageTitle: _ctx.pageTitle,
      IntermediaryItems: _ctx.breadcrumbItems
    }, null, 8, ["PageTitle", "IntermediaryItems"]),
    _createVNode(_component_AddOrEditSpecialProject, { onPageUpdate: _ctx.updateBreadCrumbTitle }, null, 8, ["onPageUpdate"])
  ], 64))
}