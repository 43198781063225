<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white p-15 p-sm-20 p-md-25"
    >
      <div class="row px-3 py-2">
<!--         <div class=" mt-20 col-12 col-md-4">
          <form class="search-box position-relative">
            <input
              type="text"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Search Order"
              v-model="searchInput"
            />
            <button
              class="bg-transparent text-primary transition p-0 border-0"
              disabled
            >
              <i class="flaticon-search-interface-symbol"></i>
            </button>
          </form>
        </div> -->
        <div class="col-md">
          <form>
            <label for="from-date" class="fw-medium">From</label>
            <input type="date" id="from-date" v-model="fromDate" class="form-control shadow-none text-black fs-md-15">
          </form>
        </div>
        <div class="col-md">
          <form>
            <label for="to-date" class="fw-medium">To</label>
            <input type="date" id="to-date" v-model="toDate" class="form-control shadow-none text-black fs-md-15">
          </form>
        </div>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25 position-relative">
      <div v-if="isLoading" class="relative-overlay">
        <span class="spinner-border text-light spinner-overlay" role="status" aria-hidden="true"></span>
      </div>
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                <SortableColumn column="id" :sortedBy="sortField" @sortClicked="sortClicked">Return Id</SortableColumn>
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                <SortableColumn column="received_at" :sortedBy="sortField" @sortClicked="sortClicked">Received</SortableColumn>
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Order #
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Reason
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Status
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Name
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Email
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="returnOrder in returnOrders" :key="returnOrder.id">
              <th class="shadow-none fw-medium text-black title ps-0">
                {{ returnOrder.id }}
              </th>
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                {{ formatTimestamp(returnOrder.received_at) }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                <router-link :to="{ name: 'OrderDetailsPage', params: { order_id: returnOrder.order.data.id  } }">{{ returnOrder.order.data.order_number }}</router-link>
              </td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                {{ returnOrder.return_order_reason }} {{ returnOrder.internal_note ? '('+returnOrder.internal_note+')' : '' }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                {{ returnOrder.return_order_status }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                {{ returnOrder.order.data.customer_first_name }} {{ returnOrder.order.data.customer_last_name }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                {{ returnOrder.order.data.customer_email }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <PaginationFooter :pagination="meta.pagination" :sortField="sortField" :sortOrder="sortOrder" />
    </div>
  </div>
</template>

<script>
import { currentPage, formatTimestamp, getTodayDateSqlFormat, get7DaysAgoDateSqlFormat } from "../../utils/helper";
import SortableColumn from "../Common/SortableColumn.vue";
import PaginationFooter from "../Common/PaginationFooter.vue";
import apiConnector from '../../utils/apiConnector';

export default {
  name: "SpecialProjectsList",
  components: {
    SortableColumn,
    PaginationFooter
  },
  data() {
    return {
      isLoading: false,
      sortField: '',
      sortOrder: '',
      fromDate: get7DaysAgoDateSqlFormat(),
      toDate: getTodayDateSqlFormat(),
      // searchInput: '',
      returnOrders: [],
      meta: {
        pagination: {
          total: 0,
          count: 0,
          per_page: 20,
          current_page: 1,
          total_pages: 1,
          links: {
            next: null,
            previous: null,
          },
        },
      },
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newQuery) {
        this.sortField = newQuery.sort_by || 'received_at';
        this.sortOrder = newQuery.sort_order || 'desc';
        // this.searchInput = newQuery.q || '';
        this.fromDate = newQuery.from || get7DaysAgoDateSqlFormat();
        this.toDate = newQuery.to || getTodayDateSqlFormat();
        this.fetchReturnOrders();
      },
    },
    // searchInput(search){
    //   this.updatePageUrl();
    // },
    fromDate(date){
      this.updatePageUrl();
    },
    toDate(date){
      this.updatePageUrl();
    }
  },
  methods: {
    async fetchReturnOrders() {
      this.isLoading = true;

      let pageUrl = `/returns?include=order&page=${currentPage()}&sorting[${this.sortField}]=${this.sortOrder}&filter[from]=${this.fromDate}&filter[to]=${this.toDate}`;

      // if (this.searchInput) url += `&q=${encodeURIComponent(this.searchInput)}`;
      try{
        const response = await apiConnector.makeGetRequest(pageUrl);
        this.returnOrders = response.data.data;
        this.meta = response.data.meta;
      }catch(error){
        if(error.response.status == 404){
          console.info('No returns found');
          this.returnOrders = [];
          this.meta = {
            pagination: {
              total: 0,
              count: 0,
              per_page: 20,
              current_page: 1,
              total_pages: 1,
              links: {
                next: null,
                previous: null,
              }
            }
          };
        }else{
          console.error('error fetchiing returns');
        }
      } finally {
        this.isLoading = false;
      }
    },

    sortClicked(sorting) {
      this.sortField = sorting.column;
      this.sortOrder = sorting.order;
      this.updatePageUrl();
    },

    updatePageUrl(page) {
      let query = {};
      if(page > 1){
        query.page = page;
      }
      if(this.sortField != 'received_at' || this.sortOrder != 'desc'){
        query.sort_by = this.sortField;
        query.sort_order = this.sortOrder;
      }
      if(this.fromDate != get7DaysAgoDateSqlFormat()){
        query.from = this.fromDate;
      }
      if(this.toDate != getTodayDateSqlFormat()){
        query.to = this.toDate;
      }
      // if(this.searchInput){
      //   query.q = this.searchInput;
      // }
      this.$router.replace({ name: 'ReturnOrdersPage', query: query }).catch(err => { console.log(err) });
    },

    formatTimestamp
  },
};
</script>