import EcommercePage from "../template-pages/Dashboard/EcommercePage.vue";
import ProjectManagementPage from "../template-pages/Dashboard/ProjectManagementPage.vue";
import SupportDeskPage from "../template-pages/Dashboard/SupportDeskPage.vue";
import LMSCoursesPage from "../template-pages/Dashboard/LMSCoursesPage.vue";
import CRMSystemPage from "../template-pages/Dashboard/CRMSystemPage.vue";
import FileManagerPage from "../template-pages/FileManagerPage.vue";
import ChangelogPage from "../template-pages/ChangelogPage.vue";
import CalendarPage from "../template-pages/CalendarPage.vue";
import ChatPage from "../template-pages/ChatPage.vue";
import ChatGroupPage from "../template-pages/ChatGroupPage.vue";
import ChatContactPage from "../template-pages/ChatContactPage.vue";
import LeadsPage from "../template-pages/CRM/LeadsPage.vue";
import LeadDetailsPage from "../template-pages/CRM/LeadDetailsPage.vue";
import ContactsListPage from "../template-pages/CRM/ContactsListPage.vue";
import CustomersListPage from "../template-pages/CRM/CustomersListPage.vue";
import EmailsPage from "../template-pages/Emails/EmailsPage.vue";
import ReadEmailPage from "../template-pages/Emails/ReadEmailPage.vue";
import ProductsGridPage from "../template-pages/Ecommerce/ProductsGridPage.vue";
import ProductsListPage from "../template-pages/Ecommerce/ProductsListPage.vue";
import AddProductPage from "../template-pages/Ecommerce/AddProductPage.vue";
import ProductDetailsPage from "../template-pages/Ecommerce/ProductDetailsPage.vue";
import OrdersListPage from "../template-pages/Ecommerce/OrdersListPage.vue";
import OrderDetailsPage from "../template-pages/Ecommerce/OrderDetailsPage.vue";
import ShoppingCartPage from "../template-pages/Ecommerce/ShoppingCartPage.vue";
import CheckoutPage from "../template-pages/Ecommerce/CheckoutPage.vue";
import CustomersPage from "../template-pages/Ecommerce/CustomersPage.vue";
import SellersListPage from "../template-pages/Ecommerce/SellersListPage.vue";
import ManageReviewsPage from "../template-pages/Ecommerce/ManageReviewsPage.vue";
import RefundPage from "../template-pages/Ecommerce/RefundPage.vue";
import InvoiceListPage from "../template-pages/Ecommerce/InvoiceListPage.vue";
import InvoiceDetailsPage from "../template-pages/Ecommerce/InvoiceDetailsPage.vue";
import OrderTrackingPage from "../template-pages/Ecommerce/OrderTrackingPage.vue";
import CoursesListPage from "../template-pages/ELearning/CoursesListPage.vue";
import CoursesGridPage from "../template-pages/ELearning/CoursesGridPage.vue";
import CourseDetailsPage from "../template-pages/ELearning/CourseDetailsPage.vue";
import LessonsPreviewPage from "../template-pages/ELearning/LessonsPreviewPage.vue";
import EditCoursePage from "../template-pages/ELearning/EditCoursePage.vue";
import ListViewPage from "../template-pages/SupportDesk/ListViewPage.vue";
import CardViewPage from "../template-pages/SupportDesk/CardViewPage.vue";
import ContactsPage from "../template-pages/SupportDesk/ContactsPage.vue";
import TicketPreviewPage from "../template-pages/SupportDesk/TicketPreviewPage.vue";
import ProjectsListPage from "../template-pages/Projects/ProjectsListPage.vue";
import ProjectsGridPage from "../template-pages/Projects/ProjectsGridPage.vue";
import CreateNewProjectPage from "../template-pages/Projects/CreateNewProjectPage.vue";
import ProjectDetailsPage from "../template-pages/Projects/ProjectDetailsPage.vue";
import ToDoListPage from "../template-pages/Projects/ToDoListPage.vue";
import TeamsPage from "../template-pages/Projects/TeamsPage.vue";
import KanbanPage from "../template-pages/KanbanPage.vue";
import EventsGridPage from "../template-pages/Events/EventsGridPage.vue";
import EventDetailsPage from "../template-pages/Events/EventDetailsPage.vue";
import TimelinePage from "../template-pages/Social/TimelinePage.vue";
import AboutPage from "../template-pages/Social/AboutPage.vue";
import ActivityPage from "../template-pages/Social/ActivityPage.vue";
import ProfileSettingsPage from "../template-pages/Social/ProfileSettingsPage.vue";
import StarterPage from "../template-pages/StarterPage.vue";
import RegisterPage from "../template-pages/Authentication/RegisterPage.vue";
import ForgotPasswordPage from "../template-pages/Authentication/ForgotPasswordPage.vue";
import ResetPasswordPage from "../template-pages/Authentication/ResetPasswordPage.vue";
import EmailConfirmationPage from "../template-pages/Authentication/EmailConfirmationPage.vue";
import UsersListPage from "../template-pages/Users/UsersListPage.vue";
import AddUserPage from "../template-pages/Users/AddUserPage.vue";
import PricingPage from "../template-pages/PricingPage.vue";
import FaqPage from "../template-pages/FaqPage.vue";
import SwiperSliderPage from "../template-pages/Miscellaneous/SwiperSliderPage.vue";
import PrivacyPolicyPage from "../template-pages/Miscellaneous/PrivacyPolicyPage.vue";
import TermsConditionsPage from "../template-pages/Miscellaneous/TermsConditionsPage.vue";
import FormOverviewPage from "../template-pages/Forms/FormOverviewPage.vue";
import FormControlPage from "../template-pages/Forms/FormControlPage.vue";
import FormSelectPage from "../template-pages/Forms/FormSelectPage.vue";
import FormChecksRadiosPage from "../template-pages/Forms/FormChecksRadiosPage.vue";
import FormRangePage from "../template-pages/Forms/FormRangePage.vue";
import FormInputGroupPage from "../template-pages/Forms/FormInputGroupPage.vue";
import FormFloatingLabelsPage from "../template-pages/Forms/FormFloatingLabelsPage.vue";
import FormLayoutPage from "../template-pages/Forms/FormLayoutPage.vue";
import FormValidationPage from "../template-pages/Forms/FormValidationPage.vue";
import FormWizardPage from "../template-pages/Forms/FormWizardPage.vue";
import ErrorPage from "../template-pages/ErrorPage.vue";
import TablesPage from "../template-pages/TablesPage.vue";
import ChartsPage from "../template-pages/Charts/ChartsPage.vue";
import MixedChartsPage from "../template-pages/Charts/MixedChartsPage.vue";
import FlaticonsPage from "../template-pages/Icons/FlaticonsPage.vue";
import PhosphoriconsPage from "../template-pages/Icons/PhosphoriconsPage.vue";
import GoogleMapsPage from "../template-pages/Maps/GoogleMapsPage.vue";
import LeafletMapPage from "../template-pages/Maps/LeafletMapPage.vue";
import TreePage from "../template-pages/ExtendedUI/TreePage.vue";
import EditorsPage from "../template-pages/ExtendedUI/EditorsPage.vue";
import AccordionPage from "../template-pages/UIElements/AccordionPage.vue";
import AlertsPage from "../template-pages/UIElements/AlertsPage.vue";
import AvatarsPage from "../template-pages/UIElements/AvatarsPage.vue";
import BadgesPage from "../template-pages/UIElements/BadgesPage.vue";
import BreadcrumbPage from "../template-pages/UIElements/BreadcrumbPage.vue";
import ButtonsPage from "../template-pages/UIElements/ButtonsPage.vue";
import ButtonGroupPage from "../template-pages/UIElements/ButtonGroupPage.vue";
import CarouselPage from "../template-pages/UIElements/CarouselPage.vue";
import CloseButtonPage from "../template-pages/UIElements/CloseButtonPage.vue";
import ColorBackgroundPage from "../template-pages/UIElements/ColorBackgroundPage.vue";
import ColoredLinksPage from "../template-pages/UIElements/ColoredLinksPage.vue";
import CollapsePage from "../template-pages/UIElements/CollapsePage.vue";
import DropdownsPage from "../template-pages/UIElements/DropdownsPage.vue";
import DatepickerPage from "../template-pages/UIElements/DatepickerPage.vue";
import FiguresPage from "../template-pages/UIElements/FiguresPage.vue";
import IconLinkPage from "../template-pages/UIElements/IconLinkPage.vue";
import ListGroupPage from "../template-pages/UIElements/ListGroupPage.vue";
import ModalPage from "../template-pages/UIElements/ModalPage.vue";
import NavsTabsPage from "../template-pages/UIElements/NavsTabsPage.vue";
import OffcanvasPage from "../template-pages/UIElements/OffcanvasPage.vue";
import PaginationPage from "../template-pages/UIElements/PaginationPage.vue";
import PlaceholdersPage from "../template-pages/UIElements/PlaceholdersPage.vue";
import PopoversPage from "../template-pages/UIElements/PopoversPage.vue";
import ProgressPage from "../template-pages/UIElements/ProgressPage.vue";
import RatioPage from "../template-pages/UIElements/RatioPage.vue";
import ScrollspyPage from "../template-pages/UIElements/ScrollspyPage.vue";
import StacksPage from "../template-pages/UIElements/StacksPage.vue";
import SpinnersPage from "../template-pages/UIElements/SpinnersPage.vue";
import UITablesPage from "../template-pages/UIElements/UITablesPage.vue";
import ToastsPage from "../template-pages/UIElements/ToastsPage.vue";
import UiTimelinePage from "../template-pages/UIElements/UiTimelinePage.vue";
import TimepickerPage from "../template-pages/UIElements/TimepickerPage.vue";
import TooltipsPage from "../template-pages/UIElements/TooltipsPage.vue";
import TypographyPage from "../template-pages/UIElements/TypographyPage.vue";
import BackgroundPage from "../template-pages/Utilities/BackgroundPage.vue";
import BordersPage from "../template-pages/Utilities/BordersPage.vue";
import ColorsPage from "../template-pages/Utilities/ColorsPage.vue";
import ClearfixPage from "../template-pages/Utilities/ClearfixPage.vue";
import DisplayPage from "../template-pages/Utilities/DisplayPage.vue";
import FlexPage from "../template-pages/Utilities/FlexPage.vue";
import FloatPage from "../template-pages/Utilities/FloatPage.vue";
import FocusRingPage from "../template-pages/Utilities/FocusRingPage.vue";
import GridPage from "../template-pages/Utilities/GridPage.vue";
import GuttersPage from "../template-pages/Utilities/GuttersPage.vue";
import InteractionsPage from "../template-pages/Utilities/InteractionsPage.vue";
import LinkPage from "../template-pages/Utilities/LinkPage.vue";
import ObjectFitPage from "../template-pages/Utilities/ObjectFitPage.vue";
import OpacityPage from "../template-pages/Utilities/OpacityPage.vue";
import OverflowPage from "../template-pages/Utilities/OverflowPage.vue";
import PositionPage from "../template-pages/Utilities/PositionPage.vue";
import ShadowsPage from "../template-pages/Utilities/ShadowsPage.vue";
import SizingPage from "../template-pages/Utilities/SizingPage.vue";
import SpacingPage from "../template-pages/Utilities/SpacingPage.vue";
import TextPage from "../template-pages/Utilities/TextPage.vue";
import TextTruncationPage from "../template-pages/Utilities/TextTruncationPage.vue";
import VerticalAlignmentPage from "../template-pages/Utilities/VerticalAlignmentPage.vue";
import VerticalRulePage from "../template-pages/Utilities/VerticalRulePage.vue";
import VisuallyHiddenPage from "../template-pages/Utilities/VisuallyHiddenPage.vue";
import VisibilityPage from "../template-pages/Utilities/VisibilityPage.vue";
import ZIndexPage from "../template-pages/Utilities/ZIndexPage.vue";
import CardsPage from "../template-pages/Widgets/CardsPage.vue";
import GalleryPage from "../template-pages/PagesInner/GalleryPage.vue";
import MaintenancePage from "../template-pages/PagesInner/MaintenancePage.vue";
import NotificationsPage from "../template-pages/PagesInner/NotificationsPage.vue";
import ConnectedAccountsPage from "../template-pages/PagesInner/ConnectedAccountsPage.vue";
import ProfilePage from "../template-pages/PagesInner/ProfilePage.vue";
import SearchResultPage from "../template-pages/PagesInner/SearchResultPage.vue";
import AccountSettingsPage from "../template-pages/Settings/AccountSettingsPage.vue";
import ChangePasswordPage from "../template-pages/Settings/ChangePasswordPage.vue";
import SocialLinksPage from "../template-pages/Settings/SocialLinksPage.vue";
import SettingsPrivacyPolicyPage from "../template-pages/Settings/SettingsPrivacyPolicyPage.vue";
import BlankPage from "../template-pages/BlankPage.vue";
import LogoutPage from "../template-pages/LogoutPage.vue";

const routes = [
  {
    path: "/template",
    name: "EcommercePageTemplate",
    component: EcommercePage,
    meta: {template: true}
  },
  {
    path: "/project-management",
    name: "ProjectManagementPageTemplate",
    component: ProjectManagementPage,
    meta: {template: true}
  },
  {
    path: "/support-desk",
    name: "SupportDeskPageTemplate",
    component: SupportDeskPage,
    meta: {template: true}
  },
  {
    path: "/lms-courses",
    name: "LMSCoursesPageTemplate",
    component: LMSCoursesPage,
    meta: {template: true}
  },
  {
    path: "/crm-system",
    name: "CRMSystemPageTemplate",
    component: CRMSystemPage,
    meta: {template: true}
  },
  {
    path: "/file-manager",
    name: "FileManagerPageTemplate",
    component: FileManagerPage,
    meta: {template: true}
  },
  {
    path: "/changelog",
    name: "ChangelogPageTemplate",
    component: ChangelogPage,
    meta: {template: true}
  },
  {
    path: "/calendar",
    name: "CalendarPageTemplate",
    component: CalendarPage,
    meta: {template: true}
  },
  {
    path: "/chat",
    name: "ChatPageTemplate",
    component: ChatPage,
    meta: {template: true}
  },
  {
    path: "/chat-group",
    name: "ChatGroupPageTemplate",
    component: ChatGroupPage,
    meta: {template: true}
  },
  {
    path: "/chat-contact",
    name: "ChatContactPageTemplate",
    component: ChatContactPage,
    meta: {template: true}
  },
  {
    path: "/leads",
    name: "LeadsPageTemplate",
    component: LeadsPage,
    meta: {template: true}
  },
  {
    path: "/lead-details",
    name: "LeadDetailsPageTemplate",
    component: LeadDetailsPage,
    meta: {template: true}
  },
  {
    path: "/crm-contacts",
    name: "ContactsListPageTemplate",
    component: ContactsListPage,
    meta: {template: true}
  },
  {
    path: "/crm-customers",
    name: "CustomersListPageTemplate",
    component: CustomersListPage,
    meta: {template: true}
  },
  {
    path: "/emails",
    name: "EmailsPageTemplate",
    component: EmailsPage,
    meta: {template: true}
  },
  {
    path: "/read-email",
    name: "ReadEmailPageTemplate",
    component: ReadEmailPage,
    meta: {template: true}
  },
  {
    path: "/products-temp",
    name: "ProductsGridPageTemplate",
    component: ProductsGridPage,
    meta: {template: true}
  },
  {
    path: "/products-list",
    name: "ProductsListPageTemplate",
    component: ProductsListPage,
    meta: {template: true}
  },
  {
    path: "/add-product",
    name: "AddProductPageTemplate",
    component: AddProductPage,
    meta: {template: true}
  },
  {
    path: "/product-details",
    name: "ProductDetailsPageTemplate",
    component: ProductDetailsPage,
    meta: {template: true}
  },
  {
    path: "/orders-temp",
    name: "OrdersListPageTemplate",
    component: OrdersListPage,
    meta: {template: true}
  },
  {
    path: "/order-details-temp",
    name: "OrderDetailsPageTemplate",
    component: OrderDetailsPage,
    meta: {template: true}
  },
  {
    path: "/cart",
    name: "ShoppingCartPageTemplate",
    component: ShoppingCartPage,
    meta: {template: true}
  },
  {
    path: "/checkout",
    name: "CheckoutPageTemplate",
    component: CheckoutPage,
    meta: {template: true}
  },
  {
    path: "/customers",
    name: "CustomersPageTemplate",
    component: CustomersPage,
    meta: {template: true}
  },
  {
    path: "/sellers",
    name: "SellersListPageTemplate",
    component: SellersListPage,
    meta: {template: true}
  },
  {
    path: "/error-404",
    name: "ErrorPageTemplate",
    component: ErrorPage,
    meta: {template: true}
  },
  {
    path: "/reviews",
    name: "ManageReviewsPageTemplate",
    component: ManageReviewsPage,
    meta: {template: true}
  },
  {
    path: "/refund",
    name: "RefundPageTemplate",
    component: RefundPage,
    meta: {template: true}
  },
  {
    path: "/invoice",
    name: "InvoiceListPageTemplate",
    component: InvoiceListPage,
    meta: {template: true}
  },
  {
    path: "/invoice-details",
    name: "InvoiceDetailsPageTemplate",
    component: InvoiceDetailsPage,
    meta: {template: true}
  },
  {
    path: "/order-tracking",
    name: "OrderTrackingPageTemplate",
    component: OrderTrackingPage,
    meta: {template: true}
  },
  {
    path: "/courses-list",
    name: "CoursesListPageTemplate",
    component: CoursesListPage,
    meta: {template: true}
  },
  {
    path: "/courses-grid",
    name: "CoursesGridPageTemplate",
    component: CoursesGridPage,
    meta: {template: true}
  },
  {
    path: "/course-details",
    name: "CourseDetailsPageTemplate",
    component: CourseDetailsPage,
    meta: {template: true}
  },
  {
    path: "/lessons-preview",
    name: "LessonsPreviewPageTemplate",
    component: LessonsPreviewPage,
    meta: {template: true}
  },
  {
    path: "/edit-course",
    name: "EditCoursePageTemplate",
    component: EditCoursePage,
    meta: {template: true}
  },
  {
    path: "/support-desk-list",
    name: "ListViewPageTemplate",
    component: ListViewPage,
    meta: {template: true}
  },
  {
    path: "/support-desk-card",
    name: "CardViewPageTemplate",
    component: CardViewPage,
    meta: {template: true}
  },
  {
    path: "/contacts",
    name: "ContactsPageTemplate",
    component: ContactsPage,
    meta: {template: true}
  },
  {
    path: "/ticket-preview",
    name: "TicketPreviewPageTemplate",
    component: TicketPreviewPage,
    meta: {template: true}
  },
  {
    path: "/projects-list",
    name: "ProjectsListPageTemplate",
    component: ProjectsListPage,
    meta: {template: true}
  },
  {
    path: "/projects-grid",
    name: "ProjectsGridPageTemplate",
    component: ProjectsGridPage,
    meta: {template: true}
  },
  {
    path: "/create-new-project",
    name: "CreateNewProjectPageTemplate",
    component: CreateNewProjectPage,
    meta: {template: true}
  },
  {
    path: "/project-details",
    name: "ProjectDetailsPageTemplate",
    component: ProjectDetailsPage,
    meta: {template: true}
  },
  {
    path: "/to-do-list",
    name: "ToDoListPageTemplate",
    component: ToDoListPage,
    meta: {template: true}
  },
  {
    path: "/projects-teams",
    name: "TeamsPageTemplate",
    component: TeamsPage,
    meta: {template: true}
  },
  {
    path: "/kanban",
    name: "KanbanPageTemplate",
    component: KanbanPage,
    meta: {template: true}
  },
  {
    path: "/events",
    name: "EventsGridPageTemplate",
    component: EventsGridPage,
    meta: {template: true}
  },
  {
    path: "/event-details",
    name: "EventDetailsPageTemplate",
    component: EventDetailsPage,
    meta: {template: true}
  },
  {
    path: "/social-timeline",
    name: "TimelinePageTemplate",
    component: TimelinePage,
    meta: {template: true}
  },
  {
    path: "/social-about",
    name: "AboutPageTemplate",
    component: AboutPage,
    meta: {template: true}
  },
  {
    path: "/social-activity",
    name: "ActivityPageTemplate",
    component: ActivityPage,
    meta: {template: true}
  },
  {
    path: "/profile-settings",
    name: "ProfileSettingsPageTemplate",
    component: ProfileSettingsPage,
    meta: {template: true}
  },
  {
    path: "/starter",
    name: "StarterPageTemplate",
    component: StarterPage,
    meta: {template: true}
  },
  {
    path: "/register",
    name: "RegisterPageTemplate",
    component: RegisterPage,
    meta: {template: true}
  },
  {
    path: "/forgot-password-templ",
    name: "ForgotPasswordPageTemplate",
    component: ForgotPasswordPage,
    meta: {template: true}
  },
  {
    path: "/reset-password-templ",
    name: "ResetPasswordPageTemplate",
    component: ResetPasswordPage,
    meta: {template: true}
  },
  {
    path: "/email-confirmation",
    name: "EmailConfirmationPageTemplate",
    component: EmailConfirmationPage,
    meta: {template: true}
  },
  {
    path: "/users-list",
    name: "UsersListPageTemplate",
    component: UsersListPage,
    meta: {template: true}
  },
  {
    path: "/add-user",
    name: "AddUserPageTemplate",
    component: AddUserPage,
    meta: {template: true}
  },
  {
    path: "/pricing",
    name: "PricingPageTemplate",
    component: PricingPage,
    meta: {template: true}
  },
  {
    path: "/faq",
    name: "FaqPageTemplate",
    component: FaqPage,
    meta: {template: true}
  },
  {
    path: "/ui-swiper-slider",
    name: "SwiperSliderPageTemplate",
    component: SwiperSliderPage,
    meta: {template: true}
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicyPageTemplate",
    component: PrivacyPolicyPage,
    meta: {template: true}
  },
  {
    path: "/terms-conditions",
    name: "TermsConditionsPageTemplate",
    component: TermsConditionsPage,
    meta: {template: true}
  },
  {
    path: "/forms-overview",
    name: "FormOverviewPageTemplate",
    component: FormOverviewPage,
    meta: {template: true}
  },
  {
    path: "/forms-form-control",
    name: "FormControlPageTemplate",
    component: FormControlPage,
    meta: {template: true}
  },
  {
    path: "/forms-select",
    name: "FormSelectPageTemplate",
    component: FormSelectPage,
    meta: {template: true}
  },
  {
    path: "/forms-checks-radios",
    name: "FormChecksRadiosPageTemplate",
    component: FormChecksRadiosPage,
    meta: {template: true}
  },
  {
    path: "/forms-range",
    name: "FormRangePageTemplate",
    component: FormRangePage,
    meta: {template: true}
  },
  {
    path: "/forms-input-group",
    name: "FormInputGroupPageTemplate",
    component: FormInputGroupPage,
    meta: {template: true}
  },
  {
    path: "/forms-floating-labels",
    name: "FormFloatingLabelsPageTemplate",
    component: FormFloatingLabelsPage,
    meta: {template: true}
  },
  {
    path: "/forms-layout",
    name: "FormLayoutPageTemplate",
    component: FormLayoutPage,
    meta: {template: true}
  },
  {
    path: "/forms-validation",
    name: "FormValidationPageTemplate",
    component: FormValidationPage,
    meta: {template: true}
  },
  {
    path: "/wizard",
    name: "FormWizardPageTemplate",
    component: FormWizardPage,
    meta: {template: true}
  },
  {
    path: "/tables",
    name: "TablesPageTemplate",
    component: TablesPage,
    meta: {template: true}
  },
  {
    path: "/charts",
    name: "ChartsPageTemplate",
    component: ChartsPage,
    meta: {template: true}
  },
  {
    path: "/mixed-charts",
    name: "MixedChartsPageTemplate",
    component: MixedChartsPage,
    meta: {template: true}
  },
  {
    path: "/flaticons",
    name: "FlaticonsPageTemplate",
    component: FlaticonsPage,
    meta: {template: true}
  },
  {
    path: "/phosphoricons",
    name: "PhosphoriconsPageTemplate",
    component: PhosphoriconsPage,
    meta: {template: true}
  },
  {
    path: "/google-maps",
    name: "GoogleMapsPageTemplate",
    component: GoogleMapsPage,
    meta: {template: true}
  },
  {
    path: "/leaflet-map",
    name: "LeafletMapPageTemplate",
    component: LeafletMapPage,
    meta: {template: true}
  },
  {
    path: "/ui-tree",
    name: "TreePageTemplate",
    component: TreePage,
    meta: {template: true}
  },
  {
    path: "/editor",
    name: "EditorsPageTemplate",
    component: EditorsPage,
    meta: {template: true}
  },
  {
    path: "/ui-accordion",
    name: "AccordionPageTemplate",
    component: AccordionPage,
    meta: {template: true}
  },
  {
    path: "/ui-alerts",
    name: "AlertsPageTemplate",
    component: AlertsPage,
    meta: {template: true}
  },
  {
    path: "/ui-avatars",
    name: "AvatarsPageTemplate",
    component: AvatarsPage,
    meta: {template: true}
  },
  {
    path: "/ui-badges",
    name: "BadgesPageTemplate",
    component: BadgesPage,
    meta: {template: true}
  },
  {
    path: "/ui-breadcrumb",
    name: "BreadcrumbPageTemplate",
    component: BreadcrumbPage,
    meta: {template: true}
  },
  {
    path: "/ui-buttons",
    name: "ButtonsPageTemplate",
    component: ButtonsPage,
    meta: {template: true}
  },
  {
    path: "/ui-button-group",
    name: "ButtonGroupPageTemplate",
    component: ButtonGroupPage,
    meta: {template: true}
  },
  {
    path: "/ui-carousel",
    name: "CarouselPageTemplate",
    component: CarouselPage,
    meta: {template: true}
  },
  {
    path: "/ui-close-button",
    name: "CloseButtonPageTemplate",
    component: CloseButtonPage,
    meta: {template: true}
  },
  {
    path: "/ui-color-background",
    name: "ColorBackgroundPageTemplate",
    component: ColorBackgroundPage,
    meta: {template: true}
  },
  {
    path: "/ui-colored-links",
    name: "ColoredLinksPageTemplate",
    component: ColoredLinksPage,
    meta: {template: true}
  },
  {
    path: "/ui-collapse",
    name: "CollapsePageTemplate",
    component: CollapsePage,
    meta: {template: true}
  },
  {
    path: "/ui-dropdowns",
    name: "DropdownsPageTemplate",
    component: DropdownsPage,
    meta: {template: true}
  },
  {
    path: "/ui-datepicker",
    name: "DatepickerPageTemplate",
    component: DatepickerPage,
    meta: {template: true}
  },
  {
    path: "/ui-figures",
    name: "FiguresPageTemplate",
    component: FiguresPage,
    meta: {template: true}
  },
  {
    path: "/ui-icon-link",
    name: "IconLinkPageTemplate",
    component: IconLinkPage,
    meta: {template: true}
  },
  {
    path: "/ui-list-group",
    name: "ListGroupPageTemplate",
    component: ListGroupPage,
    meta: {template: true}
  },
  {
    path: "/ui-modal",
    name: "ModalPageTemplate",
    component: ModalPage,
    meta: {template: true}
  },
  {
    path: "/ui-navs-tabs",
    name: "NavsTabsPageTemplate",
    component: NavsTabsPage,
    meta: {template: true}
  },
  {
    path: "/ui-offcanvas",
    name: "OffcanvasPageTemplate",
    component: OffcanvasPage,
    meta: {template: true}
  },
  {
    path: "/ui-pagination",
    name: "PaginationPageTemplate",
    component: PaginationPage,
    meta: {template: true}
  },
  {
    path: "/ui-placeholders",
    name: "PlaceholdersPageTemplate",
    component: PlaceholdersPage,
    meta: {template: true}
  },
  {
    path: "/ui-popovers",
    name: "PopoversPageTemplate",
    component: PopoversPage,
    meta: {template: true}
  },
  {
    path: "/ui-progress",
    name: "ProgressPageTemplate",
    component: ProgressPage,
    meta: {template: true}
  },
  {
    path: "/ui-ratio",
    name: "RatioPageTemplate",
    component: RatioPage,
    meta: {template: true}
  },
  {
    path: "/ui-scrollspy",
    name: "ScrollspyPageTemplate",
    component: ScrollspyPage,
    meta: {template: true}
  },
  {
    path: "/ui-stacks",
    name: "StacksPageTemplate",
    component: StacksPage,
    meta: {template: true}
  },
  {
    path: "/ui-spinners",
    name: "SpinnersPageTemplate",
    component: SpinnersPage,
    meta: {template: true}
  },
  {
    path: "/ui-tables",
    name: "UITablesPageTemplate",
    component: UITablesPage,
    meta: {template: true}
  },
  {
    path: "/ui-toasts",
    name: "ToastsPageTemplate",
    component: ToastsPage,
    meta: {template: true}
  },
  {
    path: "/ui-timeline",
    name: "UiTimelinePageTemplate",
    component: UiTimelinePage,
    meta: {template: true}
  },
  {
    path: "/ui-timepicker",
    name: "TimepickerPageTemplate",
    component: TimepickerPage,
    meta: {template: true}
  },
  {
    path: "/ui-tooltips",
    name: "TooltipsPageTemplate",
    component: TooltipsPage,
    meta: {template: true}
  },
  {
    path: "/ui-typography",
    name: "TypographyPageTemplate",
    component: TypographyPage,
    meta: {template: true}
  },
  {
    path: "/ui-background",
    name: "BackgroundPageTemplate",
    component: BackgroundPage,
    meta: {template: true}
  },
  {
    path: "/ui-borders",
    name: "BordersPageTemplate",
    component: BordersPage,
    meta: {template: true}
  },
  {
    path: "/ui-colors",
    name: "ColorsPageTemplate",
    component: ColorsPage,
    meta: {template: true}
  },
  {
    path: "/ui-clearfix",
    name: "ClearfixPageTemplate",
    component: ClearfixPage,
    meta: {template: true}
  },
  {
    path: "/ui-display",
    name: "DisplayPageTemplate",
    component: DisplayPage,
    meta: {template: true}
  },
  {
    path: "/ui-flex",
    name: "FlexPageTemplate",
    component: FlexPage,
    meta: {template: true}
  },
  {
    path: "/ui-float",
    name: "FloatPageTemplate",
    component: FloatPage,
    meta: {template: true}
  },
  {
    path: "/ui-focus-ring",
    name: "FocusRingPageTemplate",
    component: FocusRingPage,
    meta: {template: true}
  },
  {
    path: "/ui-grid",
    name: "GridPageTemplate",
    component: GridPage,
    meta: {template: true}
  },
  {
    path: "/ui-gutters",
    name: "GuttersPageTemplate",
    component: GuttersPage,
    meta: {template: true}
  },
  {
    path: "/ui-interactions",
    name: "InteractionsPageTemplate",
    component: InteractionsPage,
    meta: {template: true}
  },
  {
    path: "/ui-link",
    name: "LinkPageTemplate",
    component: LinkPage,
    meta: {template: true}
  },
  {
    path: "/ui-object-fit",
    name: "ObjectFitPageTemplate",
    component: ObjectFitPage,
    meta: {template: true}
  },
  {
    path: "/ui-opacity",
    name: "OpacityPageTemplate",
    component: OpacityPage,
    meta: {template: true}
  },
  {
    path: "/ui-overflow",
    name: "OverflowPageTemplate",
    component: OverflowPage,
    meta: {template: true}
  },
  {
    path: "/ui-position",
    name: "PositionPageTemplate",
    component: PositionPage,
    meta: {template: true}
  },
  {
    path: "/ui-shadows",
    name: "ShadowsPageTemplate",
    component: ShadowsPage,
    meta: {template: true}
  },
  {
    path: "/ui-sizing",
    name: "SizingPageTemplate",
    component: SizingPage,
    meta: {template: true}
  },
  {
    path: "/ui-spacing",
    name: "SpacingPageTemplate",
    component: SpacingPage,
    meta: {template: true}
  },
  {
    path: "/ui-text",
    name: "TextPageTemplate",
    component: TextPage,
    meta: {template: true}
  },
  {
    path: "/ui-text-truncation",
    name: "TextTruncationPageTemplate",
    component: TextTruncationPage,
    meta: {template: true}
  },
  {
    path: "/ui-vertical-align",
    name: "VerticalAlignmentPageTemplate",
    component: VerticalAlignmentPage,
    meta: {template: true}
  },
  {
    path: "/ui-vertical-rule",
    name: "VerticalRulePageTemplate",
    component: VerticalRulePage,
    meta: {template: true}
  },
  {
    path: "/ui-visually-hidden",
    name: "VisuallyHiddenPageTemplate",
    component: VisuallyHiddenPage,
    meta: {template: true}
  },
  {
    path: "/ui-visibility",
    name: "VisibilityPageTemplate",
    component: VisibilityPage,
    meta: {template: true}
  },
  {
    path: "/ui-z-index",
    name: "ZIndexPageTemplate",
    component: ZIndexPage,
    meta: {template: true}
  },
  {
    path: "/cards",
    name: "CardsPageTemplate",
    component: CardsPage,
    meta: {template: true}
  },
  {
    path: "/gallery",
    name: "GalleryPageTemplate",
    component: GalleryPage,
    meta: {template: true}
  },
  {
    path: "/maintenance",
    name: "MaintenancePageTemplate",
    component: MaintenancePage,
    meta: {template: true}
  },
  {
    path: "/notifications",
    name: "NotificationsPageTemplate",
    component: NotificationsPage,
    meta: {template: true}
  },
  {
    path: "/connected-accounts",
    name: "ConnectedAccountsPageTemplate",
    component: ConnectedAccountsPage,
    meta: {template: true}
  },
  {
    path: "/profile",
    name: "ProfilePageTemplate",
    component: ProfilePage,
    meta: {template: true}
  },
  {
    path: "/search-result",
    name: "SearchResultPageTemplate",
    component: SearchResultPage,
    meta: {template: true}
  },
  {
    path: "/account-settings",
    name: "AccountSettingsPageTemplate",
    component: AccountSettingsPage,
    meta: {template: true}
  },
  {
    path: "/settings-change-password",
    name: "ChangePasswordPageTemplate",
    component: ChangePasswordPage,
    meta: {template: true}
  },
  {
    path: "/settings-social-links",
    name: "SocialLinksPageTemplate",
    component: SocialLinksPage,
    meta: {template: true}
  },
  {
    path: "/settings-privacy-policy",
    name: "SettingsPrivacyPolicyPageTemplate",
    component: SettingsPrivacyPolicyPage,
    meta: {template: true}
  },
  {
    path: "/blank-page",
    name: "BlankPageTemplate",
    component: BlankPage,
    meta: {template: true}
  },
  {
    path: "/logout-temp",
    name: "LogoutPageTemplate",
    component: LogoutPage,
    meta: { requiresNoSidebar: true, public: true },
  },
];


export default routes;