<template>
  <div>
    <BreadCrumb PageTitle="Order Details" :IntermediaryItems="breadcrumbItems" />
    <OrderDetails />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import OrderDetails from "../../components/Orders/OrderDetails.vue";

export default defineComponent({
  name: "OrderDetailsPage",
  components: {
    BreadCrumb,
    OrderDetails,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          name: 'Orders List',
          to: '/orders'
        }
      ]
    }
  }
});
</script>