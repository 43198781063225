<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white p-15 p-sm-20 p-md-25"
    >
      <div class="d-md-flex align-items-center justify-content-end">
        <div class="d-sm-flex align-items-center">
          <router-link to="/new-special-project"
            class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mt-10 mt-md-0 text-decoration-none"
          >
            Add New Special Project
            <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          </router-link>
        </div>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                ID
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                <SortableColumn column="name" :sortedBy="sortField" defaultOrder='asc' @sortClicked="sortClicked">Name</SortableColumn>
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Status
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Type
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Estimate
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Products (File)
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                <SortableColumn column="created_at" :sortedBy="sortField" @sortClicked="sortClicked">Requested</SortableColumn>
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                <SortableColumn column="updated_at" :sortedBy="sortField" @sortClicked="sortClicked">Updated</SortableColumn>
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="specialProject in specialProjects" :key="specialProject.id">
              <td class="shadow-none fw-medium text-black title ps-0">
                <div class="d-flex align-items-center">
                  {{ specialProject.id }}
                </div>
              </td>
              <th class="shadow-none fw-medium text-black title">
                <div class="d-flex align-items-center">
                  <a href="#" @click.prevent="viewSpecialProjectClicked(specialProject)" data-bs-toggle="modal" data-bs-target="#viewSpecialProjectModal">{{ specialProject.name }}</a>
                </div>
              </th>
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                {{ specialProject.status_name }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                {{ specialProject.type_name }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                {{ specialProject.total_estimate ? '$ '+specialProject.total_estimate : '...' }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                <span v-if="specialProject.stock_products.data.length">
                  <span v-for="(stockProduct, i) in specialProject.stock_products.data" :key="stockProduct.id">{{ stockProduct.upc }}<span v-if="i < specialProject.stock_products.data.length-1">, </span></span>
                  <span v-if="specialProject.file_url"> (</span>
                </span>
                <span v-if="specialProject.file_url"><a :href="specialProject.file_url" target="_blank">File</a></span>
                <span v-if="specialProject.stock_products.data.length && specialProject.file_url">)</span>
              </td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">{{ formatDate(specialProject.created_at) }}</td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">{{ formatDate(specialProject.updated_at) }}</td>
              <td
                class="shadow-none lh-1 fw-medium text-paragraph text-end pe-0"
              >
                <div class="dropdown">
                  <button
                    class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <router-link
                        :to="{path: '/special-projects/'+specialProject.id }"
                        class="dropdown-item d-flex align-items-center"
                        ><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                        Edit</router-link
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center cursor-pointer"
                        data-bs-toggle="modal" data-bs-target="#confirmDeleteModal"
                        @click.prevent="removeSpecialProjectClicked(specialProject)"
                        ><i
                          class="flaticon-delete lh-1 me-8 position-relative top-1"
                        ></i>
                        Delete</a
                      >
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <PaginationFooter :pagination="meta.pagination" :sortField="sortField" :sortOrder="sortOrder" />
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="confirmDeleteModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content" v-if="specialProjectToDelete">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Removing the special project "<span class="text-danger">{{ specialProjectToDelete.name }}</span>". Confirm?</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <p>Once this action is confirmed it cannot be undone, are you sure you want to delete the special project?</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" id="closeCofirmDeleteModal" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" v-on:click="deleteSpecialProject()" class="btn btn-outline-danger">Delete</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="viewSpecialProjectModal" tabindex="-1" aria-hidden="true" v-show="viewingSpecialProject">
    <div class="modal-dialog modal-dialog-scrollable">
      <div class="modal-content" v-if="viewingSpecialProject">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Project Details</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-9">
              <div class="content mb-10">
                <span class="d-block fw-bold text-dark-emphasis mb-1"> Name</span>
                <h3 class="fw-black fs-20">{{ viewingSpecialProject.name }}</h3>
              </div>
            </div>
            <div class="col-md-3">
              <div class="content mb-10">
                <span class="d-block fw-bold text-dark-emphasis mb-1"> Status</span>
                <h3 class="fw-black fs-15">{{ viewingSpecialProject.status_name }}</h3>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="content">
                <span class="d-block fw-bold text-dark-emphasis mb-1"> Type</span>
                <h5 class="fs-15">{{ viewingSpecialProject.type_name }}</h5>
              </div>
            </div>
            <div class="col-md-4">
              <div class="content">
                <span class="d-block fw-bold text-dark-emphasis mb-1"> Requested</span>
                <h5 class="fs-15">{{ formatDate(viewingSpecialProject.created_at) }}</h5>
              </div>
            </div>
            <div class="col-md-4" v-if="viewingSpecialProject.completed_at">
              <div class="content">
                <span class="d-block fw-bold text-dark-emphasis mb-1"> Completed</span>
                <h5 class="fs-15">{{ formatDate(viewingSpecialProject.completed_at) }}</h5>
              </div>
            </div>
          </div>
          <div class="row mb-15" v-if="viewingSpecialProject.contact_name">
            <div class="col-md-12">
              <div class="content">
                <span class="d-block fw-bold text-dark-emphasis mb-1"> Contact Details</span>
                <h5 class="fw-black">{{ viewingSpecialProject.contact_name }}<span v-if="viewingSpecialProject.contact_phone"> | {{ viewingSpecialProject.contact_phone }}</span> <span v-if="viewingSpecialProject.contact_email">| {{ viewingSpecialProject.contact_email }}</span></h5>
              </div>
            </div>
          </div>
          <div class="row mb-15 mt-15" v-if="viewingSpecialProject.total_estimate">
            <div class="col-md-3">
              <div class="content">
                <span class="d-block fw-bold text-dark-emphasis mb-1"> Total Estimate</span>
                <h5 class="fs-15">$ {{ viewingSpecialProject.total_estimate }}</h5>
              </div>
            </div>
            <div class="col-md-3" v-if="viewingSpecialProject.material_amount && viewingSpecialProject.material_amount != 0">
              <div class="content">
                <span class="d-block fw-bold text-dark-emphasis mb-1"> Material</span>
                <h5 class="fs-15">$ {{ viewingSpecialProject.material_amount }}</h5>
              </div>
            </div>
            <div class="col-md-3" v-if="viewingSpecialProject.labor_amount && viewingSpecialProject.labor_amount != 0">
              <div class="content">
                <span class="d-block fw-bold text-dark-emphasis mb-1"> Labor</span>
                <h5 class="fs-15">$ {{ viewingSpecialProject.labor_amount }}</h5>
              </div>
            </div>
            <div class="col-md-3" v-if="viewingSpecialProject.labor_hours">
              <div class="content">
                <span class="d-block fw-bold text-dark-emphasis mb-1"> Hours</span>
                <h5 class="fs-15">{{ viewingSpecialProject.labor_hours }}</h5>
              </div>
            </div>
          </div>
          <div class="row mb-15" v-if="viewingSpecialProject.stock_products.data.length">
            <div class="col-md-12">
              <div class="content">
                <span class="d-block fw-bold text-dark-emphasis mb-1"> Products</span>
                <ul class="list-group mb-15">
                  <li class="list-group-item fw-medium fs-md-15 d-flex justify-content-between" v-for="stockProduct in viewingSpecialProject.stock_products.data" :key="stockProduct.id">
                    <div class="d-flex flex-column">
                      <p class="mb-1 fs-md-15 fs-lg-16">{{ stockProduct.quantity }} x {{ stockProduct.name }}</p>
                      <small>UPC: {{ stockProduct.upc }}</small>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row mb-15">
            <div class="col-md-12">
              <div class="content">
                <span class="d-block fw-bold text-dark-emphasis mb-1"> File link</span>
                <a class="fs-20" target="_blank" :href="viewingSpecialProject.file_url">File</a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="content">
                <span class="d-block fw-bold text-dark-emphasis mb-1"> Notes</span>
                <pre class="fs-15">{{ viewingSpecialProject.notes }}</pre>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" v-if="viewingSpecialProject.is_awaiting_approval" class="btn btn-primary" @click.prevent="approveEstimateClicked(viewingSpecialProject)">Approve Estimate</button>
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updatePageUrl, currentPage, formatDate } from "../../utils/helper";
import PaginationFooter from "../Common/PaginationFooter.vue";
import SortableColumn from "../Common/SortableColumn.vue";
import apiConnector from '../../utils/apiConnector';
import showToast from '../../utils/showToast';
import { Modal } from "bootstrap";
import $ from "jquery";

export default {
  name: "SpecialProjectsList",
  components: {
    SortableColumn,
    PaginationFooter
  },
  data() {
    return {
      sortField: '',
      sortOrder: '',
      specialProjectToDelete: null,
      specialProjects: [],
      viewingSpecialProject: null,
      meta: {
        pagination: {
          total: 0,
          count: 0,
          per_page: 20,
          current_page: 1,
          total_pages: 1,
          links: {
            next: null,
            previous: null,
          },
        },
      },
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newQuery) {
        this.sortField = newQuery.sort_by || 'updated_at';
        this.sortOrder = newQuery.sort_order || 'desc';
        this.fetchSpecialProjects();
      },
    }
  },
  methods: {
    async fetchSpecialProjects() {
      let pageUrl = `/special-projects?page=${currentPage()}&sorting[${this.sortField}]=${this.sortOrder}`;
      const response = await apiConnector.makeGetRequest(pageUrl);
      this.specialProjects = response.data.data;
      this.meta = response.data.meta;
    },
    async approveSpecialProjectEstimate(specialProject) {
      let endpoint = `/special-projects/${specialProject.id}/approve`;
      const response = await apiConnector.makePostRequest(endpoint);
      let viewModal = Modal.getOrCreateInstance($('#viewSpecialProjectModal'));
      viewModal.hide();
      this.fetchSpecialProjects();
      showToast('Special project estimates successfully approved.')
    },

    async deleteSpecialProject() {
      let confirmDeleteModal = Modal.getOrCreateInstance($('#confirmDeleteModal'));
      if(confirmDeleteModal && this.specialProjectToDelete){
        await apiConnector.makeDeleteRequest('/special-projects/'+this.specialProjectToDelete.id);
        this.fetchSpecialProjects();
        showToast('Special project successfully deleted.')
        confirmDeleteModal.hide();
      }
    },
    sortClicked(sorting) {
      this.sortField = sorting.column;
      this.sortOrder = sorting.order;
      updatePageUrl(1, this.sortField, this.sortOrder, false);
    },
    viewSpecialProjectClicked(specialProject){
      this.viewingSpecialProject = specialProject;
    },
    removeSpecialProjectClicked(specialProject){
      this.specialProjectToDelete = specialProject;
    },
    approveEstimateClicked(specialProject){
      this.approveSpecialProjectEstimate(specialProject);
    },
    formatDate
  },
};
</script>