<template>
  <BreadCrumb PageTitle="Icon Link" />
  <IconLink />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BreadCrumb from "../../template-components/Common/BreadCrumb.vue";
import IconLink from "../../template-components/UIElements/IconLink/IconLink.vue";

export default defineComponent({
  name: "IconLinkPage",
  components: {
    BreadCrumb,
    IconLink,
  },
});
</script>