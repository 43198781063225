<template>
  <BreadCrumb PageTitle="Grid" />
  <GridContent />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BreadCrumb from "../../template-components/Common/BreadCrumb.vue";
import GridContent from "../../template-components/Utilities/Grid/GridContent.vue";

export default defineComponent({
  name: "GridPage",
  components: {
    BreadCrumb,
    GridContent,
  },
});
</script>