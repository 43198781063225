import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", {
    href: "#",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.sortableColumnClicked()), ["prevent"])),
    class: _normalizeClass(_ctx.sortingActiveClass()+' icon-link text-decoration-none')
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _createTextVNode(),
    _createElementVNode("i", {
      class: _normalizeClass(_ctx.sortingOrderClass()+' lh-1 position-relative top-1')
    }, null, 2)
  ], 2))
}