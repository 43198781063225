<template>
  <a href="#" @click.prevent="sortableColumnClicked()" :class="sortingActiveClass()+' icon-link text-decoration-none'">
    <slot></slot>  <i :class="sortingOrderClass()+' lh-1 position-relative top-1'"></i>
  </a>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SortableColumn",
  props: ["column", "sortedBy", 'defaultOrder'],
  emits: ['sortClicked'],
  data() {
    return {
      order: this.defaultOrder || 'desc'
    }
  },
  methods: {
    sortingActiveClass() {
      if(this.column != this.sortedBy){
        return 'link-dark';
      }
      return '';
    },
    sortingOrderClass() {
      if(this.column != this.sortedBy){
        return '';
      }
      return this.order == 'asc' ? 'flaticon-up-arrow' : 'flaticon-down-arrow';
    },
    sortableColumnClicked(){
      if(this.column == this.sortedBy){
        this.order = this.order == 'asc' ? 'desc' : 'asc';
      }
      this.$emit('sortClicked', {column: this.column, order: this.order});
    }
  }
});
</script>