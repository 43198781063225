<template>
  <BreadCrumb PageTitle="Orders List" />
  <OrdersList />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../template-components/Common/BreadCrumb.vue";
import OrdersList from "../../template-components/Ecommerce/OrdersList/OrdersList.vue";

export default defineComponent({
  name: "OrdersListPage",
  components: {
    BreadCrumb,
    OrdersList,
  },
});
</script>