<template>
  <BreadCrumb PageTitle="Pagination" />
  <PaginationContent />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BreadCrumb from "../../template-components/Common/BreadCrumb.vue";
import PaginationContent from "../../template-components/UIElements/Pagination/PaginationContent.vue";

export default defineComponent({
  name: "PaginationPage",
  components: {
    BreadCrumb,
    PaginationContent,
  },
});
</script>