import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb")!
  const _component_OrderDetails = _resolveComponent("OrderDetails")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BreadCrumb, {
      PageTitle: "Order Details",
      IntermediaryItems: _ctx.breadcrumbItems
    }, null, 8, ["IntermediaryItems"]),
    _createVNode(_component_OrderDetails)
  ]))
}