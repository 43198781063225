<template>
  <BreadCrumb :PageTitle="pageTitle" :IntermediaryItems="breadcrumbItems" />
  <AddOrEditProduct @pageUpdate="updateBreadCrumbTitle" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import AddOrEditProduct from "../../components/Products/AddOrEditProduct.vue";

export default defineComponent({
  name: "AddOrEditProductPage",
  components: {
    BreadCrumb,
    AddOrEditProduct,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          name: 'Products List',
          to: '/products'
        }
      ],
      pageTitle: this.$route.name == "EditProductPage" ? 'Edit Product' : 'Add Product'
    }
  },
  methods: {
    updateBreadCrumbTitle(page) {
      this.pageTitle = page == "EditProductPage" ? 'Edit Product' : 'Add Product';
    }
  }
});
</script>