<template>
  <header
    :class="[
      'header-area bg-white text-center text-md-start pt-15 pb-15 ps-15 pe-15 ps-md-20 pe-md-20 pe-lg-30 transition mb-25 position-fixed',
      { sticky: isSticky },
    ]"
    id="header"
  >
    <div class="row align-items-center">
      <div class="col-2 col-xl-4 col-lg-5 col-md-6">
        <div
          class="header-left-side justify-content-md-start d-flex align-items-center"
        >
          <button
            class="header-burger-menu transition position-relative lh-1 bg-transparent p-0 border-0 d-xl-none"
            id="header-burger-menu"
            @click="stateStoreInstance.onChange"
          >
            <i class="flaticon-menu-3"></i>
          </button>
<!--           <form class="search-box" @submit.prevent="handleSearch">
            <div class="input-group">
              <input type="text" class="form-control shadow-none rounded-0 border-0" placeholder="Search orders" v-model="searchQuery" />
              <button class="default-btn position-relative transition border-0 fw-medium text-white pt-8 pb-8 ps-15 pe-15 pt-md-12 pb-md-12 ps-md-20 pe-md-20" type="submit">
                <i class="flaticon-search-interface-symbol position-relative top-1"></i>
              </button>
            </div>
          </form> -->
        </div>
      </div>
      <div class="col-10 col-sm-8 col-xl-8 col-lg-7 col-md-6">
        <div
          class="header-right-side d-flex align-items-center justify-content-center justify-content-md-end"
        >
          <LightDarkSwtichBtn />

          <div class="dropdown profile-dropdown">
            <span
              class="text-start fs-14 text-black-emphasis d-flex align-items-center p-0 position-relative bg-transparent border-0 transition lh-1"
              aria-expanded="false"
            >
              <i class="flaticon-user fs-30"></i>
              <span class="title d-lg-block ms-10 ms-lg-15">
                <span class="d-block fw-bold mb-5 mb-md-8">{{ userName }}</span>
                <span class="text-body-emphasis fw-semibold fs-13">{{ merchantName }}</span>
              </span>
            </span>
            <!-- <div
              class="dropdown-menu rounded-0 bg-white border-0 start-auto end-0"
            >
              <ul class="ps-0 mb-0 list-unstyled dropdown-body">
                <li
                  class="text-body-secondary fw-semibold transition position-relative"
                >
                  <i class="flaticon-user-2"></i>
                  My Account
                  <router-link
                    to="/profile"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></router-link>
                </li>
                <li
                  class="text-body-secondary fw-semibold transition position-relative"
                >
                  <i class="flaticon-setting"></i>
                  Settings
                  <router-link
                    to="/profile-settings"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></router-link>
                </li>
                <li
                  class="text-body-secondary fw-semibold transition position-relative"
                >
                  <i class="flaticon-warning"></i>
                  Support
                  <router-link
                    to="/support-desk-card"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></router-link>
                </li>
                <li
                  class="text-body-secondary fw-semibold transition position-relative"
                >
                  <i class="flaticon-logout"></i>
                  Logout
                  <router-link
                    to="/logout"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></router-link>
                </li>
              </ul>
            </div> -->
          </div>

          <div class="dropdown warehouse-dropdown" v-if="warehouses.length > 0">
            <button
              class="dropdown-toggle text-start fs-14 text-black-emphasis d-flex align-items-center p-0 position-relative bg-transparent transition border-0 lh-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="flaticon-map fs-30"></i>
              <span class="title d-none d-lg-block ms-10 ms-lg-15">
                <span class="d-block fw-bold mb-5 mb-md-8">{{ warehouseName }}</span>
              </span>
            </button>
            <div
              class="dropdown-menu rounded-0 bg-white border-0 start-auto end-0"
            >
              <ul class="ps-0 mb-0 list-unstyled dropdown-body">
                <li
                  class="text-body-secondary fw-semibold transition position-relative"
                  v-for="warehouse in warehouses" :key="warehouse.id"
                >
                  <i class="flaticon-maps-and-flags"></i>
                  {{ warehouse.name }}
                  <a
                    @click.prevent="updateWarehouse(warehouse.id)"
                    class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none"
                  ></a>
                </li>
              </ul>
            </div>
          </div>

          <div class="dropdown apps-dropdown">

            <button
              class="dropdown-toggle p-0 position-relative bg-transparent border-0 transition lh-1"
              type="button"
              @click.prevent="logout()"
            >
                <i class="flaticon-logout"></i>
              </button>

          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LightDarkSwtichBtn from "./LightDarkSwtichBtn.vue";
import apiConnector from '../../utils/apiConnector';
import stateStore from "../../utils/store";

export default defineComponent({
  name: "MainHeader",
  emits: ["warehouseScopeUpdated"],
  components: {
    LightDarkSwtichBtn,
  },
  data(){
    return {
      userName: '',
      merchantName: '',
      warehouseName: '',
      warehouses: [],
      isSticky: false,
      searchQuery: '',
      stateStoreInstance: stateStore
    }
  },
  mounted(){
    window.addEventListener("scroll", () => {
      let scrollPos = window.scrollY;
      this.isSticky = scrollPos >= 100;
    });
    const isAuthorized = localStorage.getItem('shipbreezeAuthorized');
    if(isAuthorized){
      this.getCurrentUserData();
    }
  },
  methods: {
    async updateWarehouse (warehouseId) {
      const response = await apiConnector.makePostRequest('/users/warehouse-scope/'+warehouseId);
      this.warehouseName = response.data.data.name;
      this.$emit('warehouseScopeUpdated', warehouseId);
    },
    async getCurrentUserData () {
      const response = await apiConnector.makeGetRequest('/user');
      let userData = response.data.data;

      console.log(userData);
      this.userName = userData.name;
      this.merchantName = userData.merchant;
      this.warehouseName = userData.warehouse;
      if(userData.warehouses){
        this.warehouses = userData.warehouses;
      }
    },
    logout() {
      apiConnector.logout().then(() => {
        this.$router.push({ path: '/login' });
      }).catch(error => {
        if(error.response.status == 401){
          this.$router.push({ path: '/login' });
        }else{
          console.error('logout request failed');
        }
      });
    },
    handleSearch() {
      if (this.searchQuery) {
        this.$router.push({ name: 'OrdersListPage', query: { q: this.searchQuery, page: '1' } }).catch(err => { console.log(err) });
      }
    }
  }
});
</script>