<template>
  <BreadCrumb PageTitle="Leaflet Map" />
  <LeafletMaps />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BreadCrumb from "../../template-components/Common/BreadCrumb.vue";
import LeafletMaps from "../../template-components/Maps/LeafletMaps/LeafletMaps.vue";

export default defineComponent({
  name: "LeafletMapPage",
  components: {
    BreadCrumb,
    LeafletMaps,
  },
});
</script>