<template>
  <!-- Overlay -->
  <div v-if="isLoading" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); display: flex; justify-content: center; align-items: center; z-index: 9050;">
    <span class="spinner-border text-light" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">

    <!-- Existing template code -->
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                <SortableColumn column="days_left" :sortedBy="sortField" @sortClicked="sortClicked">Days Left</SortableColumn>
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                <SortableColumn column="name" :sortedBy="sortField" @sortClicked="sortClicked">Name</SortableColumn>
              </th>

              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                <SortableColumn column="upc" :sortedBy="sortField" @sortClicked="sortClicked">UPC</SortableColumn>
              </th>

              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                <SortableColumn column="available_for_sale" :sortedBy="sortField" @sortClicked="sortClicked">Available for Sale</SortableColumn>
              </th>

              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                <SortableColumn column="on_hand" :sortedBy="sortField" @sortClicked="sortClicked">On Hand</SortableColumn>
              </th>

              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                <SortableColumn column="allocated" :sortedBy="sortField" @sortClicked="sortClicked">Allocated</SortableColumn>
              </th>

              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                <SortableColumn column="committed" :sortedBy="sortField" @sortClicked="sortClicked">Committed</SortableColumn>
              </th>

              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                <SortableColumn column="fulfilled_per_day" :sortedBy="sortField" @sortClicked="sortClicked">Fulfilled / day</SortableColumn>
              </th>

              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                <SortableColumn column="ordered_per_day" :sortedBy="sortField" @sortClicked="sortClicked">Ordered / day</SortableColumn>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="i in inventory" :key="i.id">
              <td>{{ i.days_left}}</td>
              <td>{{ i.name}}</td>
              <td>{{ i.upc}}</td>
              <td>{{ i.available_for_sale}}</td>
              <td>{{ i.on_hand}}</td>
              <td>{{ i.allocated}}</td>
              <td>{{ i.committed}}</td>
              <td>{{ i.fulfilled_per_day}}</td>
              <td>{{ i.ordered_per_day}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <PaginationFooter :pagination="meta.pagination" :sortField="sortField" :sortOrder="sortOrder" />
    </div>
  </div>
</template>

<script>
import { updatePageUrl, currentPage } from "../../utils/helper";
import SortableColumn from "../Common/SortableColumn.vue";
import PaginationFooter from "../Common/PaginationFooter.vue";
import apiConnector from '../../utils/apiConnector';

export default {
  name: "InventoryList",
  components: {
    SortableColumn,
    PaginationFooter
  },
  data() {
    return {
      isLoading: true,
      sortField: 'available_for_sale',
      sortOrder: 'desc',
      inventory: [],
      meta: {
        pagination: {
          total: 0,
          count: 0,
          per_page: 20,
          current_page: 1,
          total_pages: 1,
          links: {
            next: null,
            previous: null,
          },
        },
      },
    };
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newQuery) {
        this.sortField = newQuery.sort_by || 'available_for_sale';
        this.sortOrder = newQuery.sort_order || 'desc';
        this.fetchInventory();
      },
    },
  },
  methods: {
    async fetchInventory() {
      this.isLoading = true;
      let url = `/inventory?withPagination=true&page=${currentPage()}&sorting[${this.sortField}]=${this.sortOrder}`;
      try {
        const response = await apiConnector.makeGetRequest(url);
        this.inventory = response.data.data;
        console.log({inventory:this.inventory})
        this.meta = response.data.meta;
        if(this.meta.pagination.current_page > 1){
          this.updateUrlPage(this.meta.pagination.current_page);
        }
      } catch (error) {
        console.error('There was an error fetching the inventory:', error);
      } 
      finally {
        this.isLoading = false;
      }
    },
    sortClicked(sorting) {
      console.log('fdsfds');
      this.sortField = sorting.column;
      this.sortOrder = sorting.order;
      updatePageUrl(1, this.sortField, this.sortOrder, false, 'available_for_sale');
    },
  },
};
</script>
