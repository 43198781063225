<template>
  <BreadCrumb PageTitle="Shadows" />
  <ShadowsContent />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BreadCrumb from "../../template-components/Common/BreadCrumb.vue";
import ShadowsContent from "../../template-components/Utilities/Shadows/ShadowsContent.vue";

export default defineComponent({
  name: "ShadowsPage",
  components: {
    BreadCrumb,
    ShadowsContent,
  },
});
</script>