<template>
  <BreadCrumb PageTitle="Inventory Reports" />
  <InventoryReports />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import InventoryReports from "../../components/Inventory/InventoryReports.vue";

export default defineComponent({
  name: "InventoryReportsPage",
  components: {
    BreadCrumb,
    InventoryReports,
  },
});
</script>