<template>
  <BreadCrumb PageTitle="Add User" />
  <AddUser />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../template-components/Common/BreadCrumb.vue";
import AddUser from "../template-components/Pricing/PricingContent.vue";

export default defineComponent({
  name: "PricingPage",
  components: {
    BreadCrumb,
    AddUser,
  },
});
</script>