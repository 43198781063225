<template>
  <div>
    <BreadCrumb PageTitle="Stock Products List" />
    <StockProductsList />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import StockProductsList from "../../components/StockProducts/StockProductsList.vue";

export default defineComponent({
  name: "StockProductsListPage",
  components: {
    BreadCrumb,
    StockProductsList,
  },
});
</script>