<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div v-if="missingProducts.length">
      <h5 class="ps-15 ps-md-25 ps-sm-20 pt-15 pt-md-25 pt-sm-20 pt-15 pt-md-25 pt-sm-20"><i class="flaticon-warning text-danger align-text-bottom" /> Missing Products</h5>
      <div class="card-body p-15 p-sm-20 p-md-25" style="max-height: 300px;">
        <div class="table-responsive" style="max-height: inherit;">
          <table class="table text-nowrap align-middle mb-0">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
                >
                  Name

                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >
                  SKU
                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >
                  Ordered units
                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >
                  Ordered amount
                </th>
                <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="missingProduct in missingProducts" :key="missingProduct.sku">
                <th class="shadow-none fw-medium text-black title ps-0">
                  <div class="d-flex align-items-center">
                    {{ missingProduct.name }}
                  </div>
                </th>
                <td class="shadow-none lh-1 fw-medium text-paragraph">
                  {{ missingProduct.sku }}
                </td>
                <td class="shadow-none lh-1 fw-medium text-paragraph">
                  {{ missingProduct.total_quantity }}
                </td>
                <td class="shadow-none lh-1 fw-medium text-paragraph">
                  $ {{ missingProduct.total_amount.toFixed(2) }}
                </td>
                <td class="shadow-none lh-1 fw-medium text-paragraph text-end pe-0">
                  <!-- <span v-for="(marketplace_order_id, i) in missingProduct.marketplace_order_ids" :key="marketplace_order_id">{{ marketplace_order_id }}<span v-if="i < missingProduct.marketplace_order_ids.length-1">, </span></span> -->
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i class="flaticon-dots"></i>
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <router-link
                          :to="{path: '/new-product', query: {name: missingProduct.name, sku: missingProduct.sku }}"
                          class="dropdown-item d-flex align-items-center"
                          ><i
                            class="flaticon-plus lh-1 me-8 position-relative top-1"
                          ></i>
                          Add This Product</router-link
                        >
                      </li>
                      <li>
                        <a
                          class="dropdown-item d-flex align-items-center cursor-pointer"
                          target="_blank"
                          :href="missingProduct.shopify_variant_edit_link"
                          ><i
                            class="flaticon-pen lh-1 me-8 position-relative top-1"
                          ></i>
                          Edit Shopify Variant</a
                        >
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      class="card-head box-shadow bg-white p-15 p-sm-20 p-md-25"
    >
      <h5 v-if="missingProducts.length" class="pb-15 pb-md-25 pb-sm-20">Products</h5>
      <div class="d-md-flex align-items-center justify-content-between">
        <form class="search-box position-relative">
          <input
            type="text"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Search product"
            v-model="searchInput"
          />
          <button
            class="bg-transparent text-primary transition p-0 border-0"
            disabled
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <div class="d-sm-flex align-items-center">
          <router-link to="/new-product"
            class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mt-10 mt-md-0 text-decoration-none"
          >
            Add New Product
            <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          </router-link>
        </div>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                <SortableColumn column="name" :sortedBy="sortField" defaultOrder='asc' @sortClicked="sortClicked">Name</SortableColumn>
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                SKU
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Type
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                UPC(s)
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                <SortableColumn column="updated_at" :sortedBy="sortField" @sortClicked="sortClicked">Last Updated</SortableColumn>
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in products" :key="product.id">
              <th class="shadow-none fw-medium text-black title ps-0">
                <div class="d-flex align-items-center">
                  {{ product.name }}
                </div>
              </th>
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                {{ product.sku }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                {{ product.type_name }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                <span v-if="product.type != 'Bundle'">{{ product.upc }}</span>
                <span v-if="product.type == 'Bundle'">
                  <span v-for="(component, i) in product.components.data" :key="component.id">{{ component.upc }}<span v-if="i < product.components.data.length-1">, </span></span>
                </span>
              </td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">{{ formatDate(product.updated_at) }}</td>
              <td
                class="shadow-none lh-1 fw-medium text-paragraph text-end pe-0"
              >
                <div class="dropdown">
                  <button
                    class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <router-link
                        :to="{path: '/products/'+product.uid }"
                        class="dropdown-item d-flex align-items-center"
                        ><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                        Edit</router-link
                      >
                    </li>
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center cursor-pointer"
                        data-bs-toggle="modal" data-bs-target="#confirmDeleteModal" :data-product-uid="product.uid"
                        @click.prevent="removeProductClicked(product)"
                        ><i
                          class="flaticon-delete lh-1 me-8 position-relative top-1"
                        ></i>
                        Delete</a
                      >
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <PaginationFooter :pagination="meta.pagination" :sortField="sortField" :sortOrder="sortOrder" :search="searchInput" />
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="confirmDeleteModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Removing the product "<span class="text-danger deleteModalProductName"></span>". Confirm?</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <p>Once this action is confirmed it cannot be undone, are you sure you want to delete the product?</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" id="closeCofirmDeleteModal" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" v-on:click="deleteProduct()" class="btn btn-outline-danger">Delete</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updatePageUrlWithSearch, currentPage, formatDate } from "../../utils/helper";
import SortableColumn from "../Common/SortableColumn.vue";
import PaginationFooter from "../Common/PaginationFooter.vue";
import apiConnector from '../../utils/apiConnector';
import showToast from '../../utils/showToast';
import { Modal } from "bootstrap";
import $ from "jquery";

export default {
  name: "ProductsList",
  components: {
    SortableColumn,
    PaginationFooter
  },
  mounted() {
    this.fetchMissingProducts();
  },
  data() {
    return {
      sortField: 'updated_at',
      sortOrder: 'desc',
      searchInput: '',
      productToDelete: null,
      missingProducts: [],
      products: [],
      meta: {
        pagination: {
          total: 0,
          count: 0,
          per_page: 20,
          current_page: 1,
          total_pages: 1,
          links: {
            next: null,
            previous: null,
          },
        },
      },
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newQuery) {
        this.sortField = newQuery.sort_by || 'updated_at';
        this.sortOrder = newQuery.sort_order || 'desc';
        this.searchInput = newQuery.q || '';
        this.fetchProducts();
      },
    },
    searchInput(search){
      // this.searchInput = search;
      updatePageUrlWithSearch(1, this.sortField, this.sortOrder, search);
    }
  },
  methods: {
    async fetchProducts() {
      let currentPage = this.$route.query.page || '1';
      let pageUrl = `/products?page=${currentPage}&sorting[${this.sortField}]=${this.sortOrder}` + (this.searchInput ? `&search=${this.searchInput}` : '');
      const response = await apiConnector.makeGetRequest(pageUrl);
      this.products = response.data.data;
      this.meta = response.data.meta;
    },

    async fetchMissingProducts() {
      const response = await apiConnector.makeGetRequest('/products/missing');
      this.missingProducts = response.data.data;
    },

    async deleteProduct() {
      let confirmDeleteModal = Modal.getInstance($('#confirmDeleteModal'));
      if(confirmDeleteModal && this.productToDelete){
        await apiConnector.makeDeleteRequest('/products/'+this.productToDelete.uid);
        updatePageUrlWithSearch(currentPage(), this.sortField, this.sortOrder, this.searchInput);
        showToast('Product successfully deleted.')
        confirmDeleteModal.hide();
      }
    },
    sortClicked(sorting) {
      this.sortField = sorting.column;
      this.sortOrder = sorting.order;
      updatePageUrlWithSearch(1, this.sortField, this.sortOrder, this.searchInput);
    },
    removeProductClicked(product){
      this.productToDelete = product;
      $('.deleteModalProductName').html(product.name);
    },
    formatDate
  },
};
</script>