import axios from "axios";
import router from "../router";
import showToast from './showToast';

const apiConnector = {
  async makeGetRequest(endpoint) {
    return this.buildAxios().get(endpoint).catch(this.handleUnauthorizedError);
  },

  async makePostRequest(endpoint, payload=null) {
    return this.buildAxios().post(endpoint, payload).catch(this.handleUnauthorizedError);
  },

  async makePatchRequest(endpoint, payload) {
    return this.buildAxios().patch(endpoint, payload).catch(this.handleUnauthorizedError);
  },

  async makeDeleteRequest(endpoint) {
    return this.buildAxios().delete(endpoint).catch(this.handleUnauthorizedError);
  },

  async logout() {
    return this.buildAxios().get(process.env.VUE_APP_SERVER_URL+'/logout').then(() => {
      localStorage.removeItem('shipbreezeAuthorized');
    });
  },

  async postForgotPassword(email) {
    let server_url = process.env.VUE_APP_SERVER_URL;
    return this.buildAxios().get(server_url+'/sanctum/csrf-cookie').then(() => {
      return this.buildAxios().post(server_url+'/forgot-password', { email: email });
    });
  },

  async postResetPassword(email, password, passwordConfirmation, token) {
    return this.buildAxios().post(process.env.VUE_APP_SERVER_URL+'/reset-password', {
      email: email,
      password: password,
      password_confirmation: passwordConfirmation,
      token: token
    });
  },

  async login(email, password) {
    let server_url = process.env.VUE_APP_SERVER_URL;
    this.buildAxios().get(server_url+'/sanctum/csrf-cookie').then(() => {
      this.buildAxios().post(server_url+'/client-login', { email: email, password: password }).then(() => {
        localStorage.setItem('shipbreezeAuthorized', true);
        document.body.classList.remove("sidebar-hide");
        document.body.classList.add("sidebar-show");
        let path = router.currentRoute.value.query.redirect ?? '/';
        let pathParts = path.split('?');
        let query = {};
        if(pathParts.length > 1){
          path = pathParts.shift();
          let queryParams = pathParts.join('?').split('&');
          for(let i=0; i < queryParams.length; i++){
            let queryParam = queryParams[i].split('=');
            query[queryParam[0]] = queryParam[1];
          }
        }
        router.push({ path: path, query: query });
      }).catch(error => {
        showToast('Authentication failed', true);
        console.error(error);
      });
    });
  },

  handleUnauthorizedError(error){
    let currentRoute = router.currentRoute.value;
    console.error('There was an error on the request '+currentRoute.fullPath, error);
    if(error.response && error.response.status == 401){
      console.error('Unauthorized');
      localStorage.removeItem('shipbreezeAuthorized');
      if(currentRoute.path != '/login'){
        router.push({ name: 'LoginPage', query: {redirect: currentRoute.fullPath} });
      }
    }
    throw error;
  },

  buildConfig(){
    return {
      headers: {
        Accept: 'application/json',
      },
      withCredentials: true,
      withXSRFToken: true,
      baseURL: process.env.VUE_APP_API_BASE_URL
    };
  },

  buildAxios(){
    return axios.create(this.buildConfig());
  }
}

export default apiConnector;
