<template>
  <BreadCrumb :PageTitle="pageTitle" :IntermediaryItems="breadcrumbItems" />
  <AddOrEditStockProduct @pageUpdate="updateBreadCrumbTitle" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import AddOrEditStockProduct from "../../components/StockProducts/AddOrEditStockProduct.vue";

export default defineComponent({
  name: "AddOrEditStockProductPage",
  components: {
    BreadCrumb,
    AddOrEditStockProduct,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          name: 'Stock Products List',
          to: '/stock-products'
        }
      ],
      pageTitle: this.$route.name == "EditStockProductPage" ? 'Edit Stock Product' : 'Add Stock Product'
    }
  },
  methods: {
    updateBreadCrumbTitle(page) {
      this.pageTitle = page == "EditStockProductPage" ? 'Edit Stock Product' : 'Add Stock Product';
    }
  }
});
</script>