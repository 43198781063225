import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BreadCrumb = _resolveComponent("BreadCrumb")!
  const _component_StockProductsList = _resolveComponent("StockProductsList")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BreadCrumb, { PageTitle: "Stock Products List" }),
    _createVNode(_component_StockProductsList)
  ]))
}