<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white p-15 p-sm-20 p-md-25"
    >
      <div class="d-md-flex align-items-center justify-content-between">
        <form class="search-box position-relative">
          <input
            type="text"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Search product"
            v-model="searchInput"
          />
          <button
            class="bg-transparent text-primary transition p-0 border-0"
            disabled
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
<!--         <div class="d-sm-flex align-items-center">
          <router-link to="/new-product"
            class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mt-10 mt-md-0 text-decoration-none"
          >
            Add New Product
            <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          </router-link>
        </div> -->
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                <SortableColumn column="name" :sortedBy="sortField" defaultOrder='asc' @sortClicked="sortClicked">Name</SortableColumn>
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                UPC
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Weight (oz)
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Dimensions: H x L x W (in)
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                <SortableColumn column="updated_at" :sortedBy="sortField" @sortClicked="sortClicked">Last Updated</SortableColumn>
              </th>
<!--               <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              >
                Actions
              </th> -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="product in products" :key="product.id">
              <th class="shadow-none fw-medium text-black title ps-0">
                <div class="d-flex align-items-center">
                  {{ product.name }}
                </div>
              </th>
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                {{ product.upc }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                {{ product.weight_oz }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                {{ product.height_in }} x {{ product.length_in }} x {{ product.width_in }}
              </td>
              <td class="shadow-none lh-1 fw-medium text-paragraph">{{ formatDate(product.updated_at) }}</td>
<!--               <td
                class="shadow-none lh-1 fw-medium text-paragraph text-end pe-0"
              >
                <router-link
                  :to="{path: '/stock-products/'+product.id }"
                  class="dropdown-item d-flex align-items-center"
                  ><i
                    class="flaticon-pen lh-1 me-8 position-relative top-1"
                  ></i>
                  Edit</router-link
                >
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
      <PaginationFooter :pagination="meta.pagination" :sortField="sortField" :sortOrder="sortOrder" :search="searchInput" />
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="confirmDeleteModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Removing the product "<span class="text-danger deleteModalProductName"></span>". Confirm?</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <p>Once this action is confirmed it cannot be undone, are you sure you want to delete the product?</p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" id="closeCofirmDeleteModal" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" v-on:click="deleteProduct()" class="btn btn-outline-danger">Delete</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updatePageUrlWithSearch, currentPage, formatDate } from "../../utils/helper";
import SortableColumn from "../Common/SortableColumn.vue";
import PaginationFooter from "../Common/PaginationFooter.vue";
import apiConnector from '../../utils/apiConnector';
import showToast from '../../utils/showToast';
import { Modal } from "bootstrap";
import $ from "jquery";

export default {
  name: "StockProductsList",
  components: {
    SortableColumn,
    PaginationFooter
  },
  data() {
    return {
      sortField: 'updated_at',
      sortOrder: 'desc',
      searchInput: '',
      productToDelete: null,
      missingProducts: [],
      products: [],
      meta: {
        pagination: {
          total: 0,
          count: 0,
          per_page: 20,
          current_page: 1,
          total_pages: 1,
          links: {
            next: null,
            previous: null,
          },
        },
      },
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newQuery) {
        this.sortField = newQuery.sort_by || 'updated_at';
        this.sortOrder = newQuery.sort_order || 'desc';
        this.searchInput = newQuery.q || '';
        this.fetchProducts();
      },
    },
    searchInput(search){
      // this.searchInput = search;
      updatePageUrlWithSearch(1, this.sortField, this.sortOrder, search);
    }
  },
  methods: {
    async fetchProducts() {
      let currentPage = this.$route.query.page || '1';
      let pageUrl = `/stock_products?page=${currentPage}&sorting[${this.sortField}]=${this.sortOrder}` + (this.searchInput ? `&search=${this.searchInput}` : '');
      const response = await apiConnector.makeGetRequest(pageUrl);
      this.products = response.data.data;
      this.meta = response.data.meta;
    },

    sortClicked(sorting) {
      this.sortField = sorting.column;
      this.sortOrder = sorting.order;
      updatePageUrlWithSearch(1, this.sortField, this.sortOrder, this.searchInput);
    },
    formatDate
  },
};
</script>