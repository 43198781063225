<template>
  <BreadCrumb :PageTitle="pageTitle" :IntermediaryItems="breadcrumbItems" />
  <AddOrEditSpecialProject @pageUpdate="updateBreadCrumbTitle" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import AddOrEditSpecialProject from "../../components/SpecialProjects/AddOrEditSpecialProject.vue";

export default defineComponent({
  name: "AddOrEditSpecialProjectPage",
  components: {
    BreadCrumb,
    AddOrEditSpecialProject,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          name: 'Special Projects List',
          to: '/special-projects'
        }
      ],
      pageTitle: this.$route.name == "EditSpecialProjectPage" ? 'Edit Special Project' : 'Add Special Project'
    }
  },
  methods: {
    updateBreadCrumbTitle(page) {
      this.pageTitle = page == "EditSpecialProjectPage" ? 'Edit Special Project' : 'Add Special Project';
    }
  }
});
</script>