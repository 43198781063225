<template>
  <!-- Overlay -->
  <div v-if="isLoading" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); display: flex; justify-content: center; align-items: center; z-index: 9050;">
    <span class="spinner-border text-light" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span>
  </div>

  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <!-- Your existing card-body and table code -->

    <!-- Added Date Picker and Download Buttons -->
    <div class="card-body">
      <h5>Current Inventory By Stock Lots</h5>
      <div class="row align-items-center justify-content-between">
        <!-- Date Picker -->
        <div class="col-auto">
          <div class="input-group mb-3">
            <button class="btn btn-primary" @click="downloadStockLotsCSV">Download CSV</button>
          </div>
        </div>
      </div>
      <br/>
      <br/>
      <h5>Inventory Snapshot Download</h5>
      <div class="row align-items-center justify-content-between">
        <!-- Date Picker -->
        <div class="col-auto">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">Choose Date</span>
            <input ref="datePicker" type="date" class="form-control" placeholder="mm/dd/yyyy" aria-label="Choose Date" aria-describedby="basic-addon1">
            <button class="btn btn-primary" @click="downloadSnapshotCSV">Download CSV</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Your existing pagination code -->
  </div>
</template>

<script>
import apiConnector from '../../utils/apiConnector';

export default {
  name: "InventoryReports",
  data() {
    return {
      orderDetails: {},
      isLoading: false,
    };
  },
  // Your existing Vue component options
 methods: {
    async downloadSnapshotCSV() {
      const date = this.$refs.datePicker.value;
      if (!date) {
        alert('Please select a date.');
        return;
      }

      try {

        this.isLoading = true;
        const endpoint = `${process.env.VUE_APP_API_BASE_URL}/inventory/snapshotCsv`;
        const response = await apiConnector.makeGetRequest(endpoint, {
          responseType: 'blob'
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `inventory_snapshot_${date}.csv`); // or any other extension
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Error downloading the CSV file: ', error);
        alert('There was an error downloading the file.');
      } finally {
        this.isLoading = false;
      }
    },
    async downloadStockLotsCSV() {

      try {

        this.isLoading = true;
        const endpoint = `${process.env.VUE_APP_API_BASE_URL}/inventory/stockLotsCsv`;
        const response = await apiConnector.makeGetRequest(endpoint, {
          responseType: 'blob'
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `inventory_stock_lots.csv`); // or any other extension
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error('Error downloading the CSV file: ', error);
        alert('There was an error downloading the file.');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

