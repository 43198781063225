<template>
  <BreadCrumb PageTitle="Logout" />
  <LogoutContent />
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";

import BreadCrumb from "../template-components/Common/BreadCrumb.vue";
import LogoutContent from "../template-components/Logout/LogoutContent.vue";

export default defineComponent({
  name: "LogoutPage",
  components: {
    BreadCrumb,
    LogoutContent,
  },
  setup() {
    onMounted(() => {
      localStorage.removeItem('userData');
      // Redirect to login or another page if needed
      window.location.href = '/login';
    });
  },
});
</script>
