<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-product-box">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <form v-on:submit.prevent="submitCreateOrUpdateProduct">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10"
                >Product Name</label
              >
              <input
                type="text"
                name="productName"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="e.g. Branded Smart Watch"
                v-model="productName"
                v-on:blur="validateProductName"
              />
              <div class="invalid-feedback" id='productNameError'></div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10"
                >UPC</label
              >
              <input
                type="text"
                name="productUpc"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="e.g. 850003376356"
                v-model="productUpc"
                v-on:blur="validateUpc"
              />
              <div class="invalid-feedback" id='productUpcError'></div>
            </div>
          </div>
          <div class="col-md-12">
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
              {{ product ? 'Update' : 'Create' }} Product
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import showToast from '../../utils/showToast';
import apiConnector from '../../utils/apiConnector';
import router from "../../router";
import { Modal } from "bootstrap";
import $ from "jquery";

export default defineComponent({
  name: "AddOrEditStockProduct",
  emits: ["pageUpdate"],
  setup: () => {
    const productTypes = [
      {
        type: "simple",
        name: "Simple"
      },
      {
        type: "bundle",
        name: "Bundle"
      },
      {
        type: "rush_handling",
        name: "Rush Handling"
      },
      {
        type: "insurance",
        name: "Insurance"
      },
      {
        type: "ignore",
        name: "Ignore"
      },
      {
        type: "gift_card",
        name: "Gift Card"
      },
      {
        type: "gift_message",
        name: "Gift Message"
      }
    ];
    return { productTypes };
  },
  mounted(){
    if(this.$route.name == "EditStockProductPage"){
      this.getEditProduct();
    }
  },
  data() {
    const query = this.$route.query;
    this.$router.replace({'query': undefined});
    return {
      product: null as any,
      productName: query.name ?? '',
      productSku: query.sku ?? '',
      productType: null,
      productUpc: '',
      components: [] as Array<any>,
      newComponentQty: 1,
      newComponentUid: null,
      returnedProducts: [] as Array<any>,
      newComponentOptions: [] as Array<any>,
      newComponentProductSearch: '',
      updateComponentUid: null
    }
  },
  methods: {
    async getEditProduct() {
      if(!this.$route.params.id){
        return false;
      }
      const response = await apiConnector.makeGetRequest('/stock_products/'+this.$route.params.id);
      this.product = response.data.data;
      this.productName = this.product.name;
      this.productUpc = this.product.upc;
    },
    async submitCreateOrUpdateProduct() {
      if(!this.validates()){
        return false;
      }
      const payload = this.buildProductPayload();
      let response = null as any;
      if(this.$route.name == "EditStockProductPage"){
        if(!this.product){
          return false;
        }
        response = await apiConnector.makePatchRequest(`/stock_products/${this.product.id}`, payload);
      }else{
        response = await apiConnector.makePostRequest('/stock_products', payload);
      }
      if(response && response.data && response.data.data){
        let action = this.$route.name == "EditStockProductPage" ? 'Updated' : 'Created';
        router.push({path: '/stock_products/'+response.data.data.id});
        showToast(`Product Successfully ${action}`);
        if(this.$route.name != "EditStockProductPage"){
          this.product = response.data.data;
          this.$emit('pageUpdate', 'EditStockProductPage');
        }
      }
    },
    buildProductPayload(){
      let payload = {
        name: this.productName,
        sku: this.productUpc,
      };
      return payload;
    },
    validates(){
      let nameValidation = this.validateProductName();
      let upcValidation = this.validateUpc();
      return nameValidation && upcValidation;
    },
    validateProductName(){
      if(!this.productName || this.productName.length < 3){
        $('input[name=productName]').addClass('is-invalid');
        let message = !this.productName ? 'Product name is required' : 'Product name needs to be at least 3 chars long';
        $('#productNameError').html(message);
        return false
      }
      $('input[name=productName]').removeClass('is-invalid');
      $('#productNameError').html('');
      return true;
    },
    validateUpc(){
      if(this.productType == 'simple' && (!this.productUpc || this.productUpc.length < 3)){
        $('input[name=productUpc]').addClass('is-invalid');
        let message = !this.productUpc ? 'Product UPC is required for simple product' : 'Product UPC needs to be at least 3 digits long';
        $('#productUpcError').html(message);
        return false
      }
      $('input[name=productUpc]').removeClass('is-invalid');
      $('#productUpcError').html('');
      return true;
    },
    validateComponents(){
      const missingComponentsMsg = $('.list-group-item-warning');
      if(this.productType == 'bundle' && (this.components.length == 0)){
        missingComponentsMsg.addClass('list-group-item-danger').removeClass('list-group-item-warning');
        missingComponentsMsg.append(' (Product components are required for bundles)');
        return false
      }
      missingComponentsMsg.addClass('list-group-item-warning').removeClass('list-group-item-danger');
      $('#productUpcError').html('');
      return true;
    },
  },
});
</script>