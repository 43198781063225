<template>
  <div class="card mb-25 border-0 rounded-0 bg-white add-special-product-box">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <form v-on:submit.prevent="submitCreateOrUpdateSpecialProject">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10"
                >Project Name</label
              >
              <input
                type="text"
                name="productName"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="e.g. Resticker Lot Number"
                v-model="projectName"
                v-on:blur="validateProjectName"
              />
              <div class="invalid-feedback" id='productNameError'></div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Select Type
              </label>
              <select class="form-select shadow-none fw-semibold rounded-0"
                name="projectType"
                v-model="projectType"
                v-on:blur="validateProjectType"
                v-on:change="validateProjectType"
              >
                <option :value="null" disabled>-- Special Project Type --</option>
                <option v-for="typeObj in projectTypes" :value="typeObj.type" :key="typeObj.type">{{ typeObj.name }}</option>
              </select>
              <div class="invalid-feedback" id='projectTypeError'></div>
            </div>
          </div>
          <div class="row">
            <div class="d-flex mb-15 justify-content-between">
              <h5 class="card-title fw-bold" v-if="addContactPerson">
                Contact Person
              </h5>
              <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-outline-primary btn-sm position-relative" v-on:click="addContactPersonClicked()" v-if="!addContactPerson">Add Contact Person</button>
                <button type="button" class="btn btn-outline-danger btn-sm position-relative" v-on:click="removeContactPersonClicked()" v-if="addContactPerson">Remove</button>
              </div>
            </div>
            <div class="col-md-4" v-if="addContactPerson">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10">
                  Name
                </label>
                <input
                  type="text"
                  name="contactName"
                  class="form-control shadow-none rounded-0 text-black"
                  placeholder="e.g. Joe Smith"
                  minlength="3"
                  v-model="contactName"
                  v-on:blur="validateContactName"
                />
                <div class="invalid-feedback" id='contactNameError'></div>
              </div>
            </div>
            <div class="col-md-4" v-if="addContactPerson">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10"
                  >Phone</label
                >
                <input
                  type="tel"
                  name="contactPhone"
                  class="form-control shadow-none rounded-0 text-black"
                  placeholder="e.g. (999) 999-9999"
                  v-model="contactPhone"
                  v-on:blur="validatePhone"
                />
                <div class="invalid-feedback" id='contactPhoneError'></div>
              </div>
            </div>
            <div class="col-md-4" v-if="addContactPerson">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10"
                  >Email</label
                >
                <input
                  type="email"
                  name="contactEmail"
                  class="form-control shadow-none rounded-0 text-black"
                  placeholder="e.g. name@merchant.com"
                  v-model="contactEmail"
                  v-on:blur="validateEmail"
                />
                <div class="invalid-feedback" id='contactEmailError'></div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="d-flex mb-15 justify-content-between">
              <h5 class="card-title fw-bold" v-if="stockProducts.length">
                Products
              </h5>
              <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-outline-primary btn-sm position-relative" data-bs-toggle="modal" data-bs-target="#addComponentModal" v-on:click="addStockProductClicked()">Add Products</button>
              </div>
            </div>
            <ul class="list-group mb-15" v-if="stockProducts.length">
              <li class="list-group-item fw-medium fs-md-15 d-flex justify-content-between" v-for="stockProduct in stockProducts" :key="stockProduct.id">
                <div class="d-flex flex-column">
                  <p class="mb-1 fs-md-15 fs-lg-16">{{ stockProduct.quantity }} x {{ stockProduct.name }}</p>
                  <small>UPC: {{ stockProduct.upc }}</small>
                </div>
                <div class="dropdown">
                  <button
                    class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition fs-20 no-after text-paragraph"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        data-bs-toggle="modal" data-bs-target="#addComponentModal"
                        class="dropdown-item d-flex align-items-center"
                        v-on:click="editStockProduct(stockProduct.id)"
                        ><i
                          class="flaticon-pen lh-1 me-8 position-relative top-1"
                        ></i>
                        Edit
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item d-flex align-items-center cursor-pointer"
                        v-on:click="deleteStockProduct(stockProduct.id)"
                        ><i
                          class="flaticon-delete lh-1 me-8 position-relative top-1"
                        ></i>
                        Delete</a
                      >
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-md-12">
            <div class="d-flex mb-15" v-if="!addProjectFileUrl">
              <button type="button" class="btn btn-outline-primary btn-sm position-relative ml-1" v-on:click="addFileUrlClicked()">Add File Url</button>
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25"  v-if="addProjectFileUrl">
              <label class="d-block text-black fw-semibold mb-10">
                File Url
              </label>
              <div class="input-group">
                <input
                  type="url"
                  name="projectFileUrl"
                  class="form-control shadow-none rounded-0 text-black"
                  placeholder="e.g. www.example.com/file-name"
                  minlength="5"
                  v-model="projectFileUrl"
                  v-on:blur="validateProjectFileUrl"
                />
                <button class="btn btn-danger position-relative transition border-0 fw-medium text-white pt-8 pb-8 ps-15 pe-15 pt-md-12 pb-md-12 ps-md-20 pe-md-20" v-on:click="removeFileUrlClicked()"><i class="flaticon-close position-relative top-1"></i></button>
                <div class="invalid-feedback" id='projectFileUrlError'></div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10"
                >Notes / Instructions</label
              >
              <textarea
                type="text"
                name="projectNotes"
                rows=5
                class="form-control shadow-none rounded-0 text-black"
                minlength="10"
                v-model="projectNotes"
                v-on:blur="validateProjectNotes"
              />
              <div class="invalid-feedback" id='projectNotesError'></div>
            </div>
          </div>
          <div class="col-md-12">
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
              type="submit"
            >
              {{ specialProject ? 'Update' : 'Create' }} Special Project
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="addComponentModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">{{ updateStockProductId ? 'Edit' : 'Add' }} Product</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-2">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10"
                  >Quantity</label
                >
                <input
                  type="number"
                  step="1"
                  class="form-control shadow-none rounded-0 text-black"
                  v-model="newStockProductQty"
                />
              </div>
            </div>
            <div class="col-md-10">
              <div class="form-group mb-15 mb-sm-20 mb-md-25">
                <label class="d-block text-black fw-semibold mb-10"
                  >Product</label
                >
                <model-select
                  class="form-control shadow-none rounded-0 text-black"
                  placeholder="e.g. Black Wristband"
                  name="newStockProductId"
                  v-model="newStockProductId"
                  :options="newStockProductOptions"
                  @searchchange="updateProductSearch"
                >
                </model-select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" v-on:click="addOrUpdateStockProduct" class="btn btn-outline-primary">{{ updateStockProductId ? 'Update' : 'Add'}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import "vue-search-select/dist/VueSearchSelect.css"
import { defineComponent } from "vue";
import { ModelSelect } from "vue-search-select";
import showToast from '../../utils/showToast';
import apiConnector from '../../utils/apiConnector';
import router from "../../router";
import { Modal } from "bootstrap";
import $ from "jquery";

export default defineComponent({
  name: "AddOrEditSpecialProject",
  emits: ["pageUpdate"],
  components: {
    ModelSelect
  },
  setup: () => {
    const projectTypes = [
      {
        type: "relabeling",
        name: "Relabeling"
      },
      {
        type: "discard",
        name: "Discard"
      },
      {
        type: "bundle",
        name: "Bundle"
      },
      {
        type: "other",
        name: "Others"
      }
    ];
    return { projectTypes };
  },
  mounted(){
    if(this.$route.name == "EditSpecialProjectPage"){
      this.getEditSpecialProject();
    }
  },
  data() {
    return {
      specialProject: null as any,
      projectName: '',
      contactName: '',
      contactPhone: '',
      contactEmail: '',
      projectType: null,
      projectFileUrl: '',
      projectNotes: '',
      stockProducts: [] as Array<any>,
      newStockProductQty: 1,
      newStockProductId: null,
      returnedStockProducts: [] as Array<any>,
      newStockProductOptions: [] as Array<any>,
      newStockProductSearch: '',
      updateStockProductId: null,
      addProjectFileUrl: false,
      addContactPerson: false,
    }
  },
  methods: {
    async searchStockProducts(search=null) {
      const response = await apiConnector.makeGetRequest(`/stock_products?search=${search}`);
      this.returnedStockProducts = response.data.data;
      this.newStockProductOptions = this.returnedStockProducts.map(product => { return {
        text: product.name + ' / ' + product.upc,
        value: product.id
      }});
    },
    async getEditSpecialProject() {
      if(!this.$route.params.id){
        return false;
      }
      const response = await apiConnector.makeGetRequest('/special-projects/'+this.$route.params.id);
      this.specialProject = response.data.data;
      this.projectName = this.specialProject.name;
      this.contactName = this.specialProject.contact_name;
      this.contactPhone = this.specialProject.contact_phone;
      this.contactEmail = this.specialProject.contact_email;
      this.projectType = this.specialProject.type;
      this.projectFileUrl = this.specialProject.file_url;
      this.projectNotes = this.specialProject.notes;
      this.stockProducts = this.specialProject.stock_products.data;

      this.addContactPerson = Boolean(this.contactName);
      this.addProjectFileUrl = Boolean(this.projectFileUrl);
    },
    async submitCreateOrUpdateSpecialProject() {
      if(!this.validates()){
        return false;
      }
      const payload = this.buildSpecialProjectPayload();
      let response = null as any;
      if(this.$route.name == "EditSpecialProjectPage"){
        if(!this.specialProject){
          return false;
        }
        response = await apiConnector.makePatchRequest(`/special-projects/${this.specialProject.id}`, payload);
      }else{
        response = await apiConnector.makePostRequest('/special-projects', payload);
      }
      if(response && response.data && response.data.data){
        let action = this.$route.name == "EditSpecialProjectPage" ? 'Updated' : 'Created';
        router.push({path: '/special-projects/'+response.data.data.id});
        showToast(`Product Successfully ${action}`);
        if(this.$route.name != "EditSpecialProjectPage"){
          this.specialProject = response.data.data;
          this.$emit('pageUpdate', 'EditSpecialProjectPage');
        }
      }
    },
    buildSpecialProjectPayload(){
      let payload = {
        name: this.projectName,
        contact_name: null as any,
        contact_phone: null as any,
        contact_email: null as any,
        file_url: null as any,
        type: this.projectType,
        stock_products: this.stockProducts.map(product => product.id),
        stock_product_quantities: this.stockProducts.map(product => product.quantity),
        notes: this.projectNotes
      };
      if(this.addContactPerson){
        payload.contact_name = this.contactName;
        if(this.contactPhone){
          payload.contact_phone = this.contactPhone;
        }
        if(this.contactEmail){
          payload.contact_email = this.contactEmail;
        }
      }
      if(this.addProjectFileUrl){
        payload.file_url = this.projectFileUrl;
      }
      return payload;
    },
    validates(){
      let nameValidation = this.validateProjectName();
      let typeValidation = this.validateProjectType();
      let contactNameValidation = this.validateContactName();
      let phoneValidation = this.validatePhone();
      let emailValidation = this.validateEmail();
      let fileUrlValidation = this.validateFileUrl();
      let notesValidation = this.validateNotes();
      return nameValidation && typeValidation && contactNameValidation &&
        phoneValidation && emailValidation && fileUrlValidation && notesValidation;
    },
    validateProjectName(){
      if(!this.projectName || this.projectName.length < 3){
        $('input[name=productName]').addClass('is-invalid');
        let message = !this.projectName ? 'Project name is required' : 'Project name needs to be at least 3 chars long';
        $('#productNameError').html(message);
        return false
      }
      $('input[name=productName]').removeClass('is-invalid');
      $('#productNameError').html('');
      return true;
    },
    validateProjectType(){
      if(!this.projectType){
        $('select[name=projectType]').addClass('is-invalid');
        let message = 'Project type is required';
        $('#projectTypeError').html(message);
        return false
      }
      $('select[name=projectType]').removeClass('is-invalid');
      $('#projectTypeError').html('');
      return true;
    },
    validateContactName(){
      if(this.addContactPerson && (!this.contactName || this.contactName.length < 3)){
        $('input[name=contactName]').addClass('is-invalid');
        let message = !this.contactName ? 'Please enter the contact name' : 'Contact name needs to be at least 3 chars long';
        $('#contactNameError').html(message);
        return false
      }
      $('input[name=contactName]').removeClass('is-invalid');
      $('#contactNameError').html('');
      return true;
    },
    validatePhone(){
      if(this.addContactPerson && (!this.contactPhone || this.contactPhone.length < 9) && !this.contactEmail){
        $('input[name=contactPhone]').addClass('is-invalid');
        let message = 'Either contact phone or email is required';
        if(this.contactPhone && this.contactPhone.length < 9){
          message = 'Please enter a valid phone';
        }
        $('#contactPhoneError').html(message);
        return false
      }
      $('input[name=contactPhone]').removeClass('is-invalid');
      $('#contactPhoneError').html('');
      return true;
    },
    validateEmail(){
      if(this.addContactPerson && (!this.contactEmail || this.contactEmail.length < 5) && !this.contactPhone){
        $('input[name=contactEmail]').addClass('is-invalid');
        let message = 'Either contact phone or email is required';
        if(this.contactEmail && this.contactEmail.length < 5){
          message = 'Please enter a email';
        }
        $('#contactEmailError').html(message);
        return false
      }
      $('input[name=contactEmail]').removeClass('is-invalid');
      $('#contactEmailError').html('');
      return true;
    },
    validateFileUrl(){
      console.log('validateFileUrl', this.projectFileUrl, this.addProjectFileUrl);
      if(this.addProjectFileUrl && (!this.projectFileUrl || this.projectFileUrl.length < 5)){
        $('input[name=projectFileUrl]').addClass('is-invalid');
        let message = !this.projectFileUrl ? 'Please enter the file URL' : 'The file URL needs to be valid';
        $('#projectFileUrlError').html(message);
        return false
      }
      $('input[name=projectFileUrl]').removeClass('is-invalid');
      $('#projectFileUrlError').html('');
      return true;
    },
    validateNotes(){
      if(!this.projectNotes || this.projectNotes.length < 3){
        $('input[name=projectNotes]').addClass('is-invalid');
        let message = !this.projectNotes ? 'Product UPC is required for simple product' : 'Product UPC needs to be at least 3 digits long';
        $('#contactEmailError').html(message);
        return false
      }
      $('input[name=projectNotes]').removeClass('is-invalid');
      $('#projectNotesError').html('');
      return true;
    },
    addContactPersonClicked(){
      this.addContactPerson = true;
    },
    removeContactPersonClicked(){
      this.addContactPerson = false;
    },
    addStockProductClicked(){
      this.newStockProductQty = 1;
      this.newStockProductSearch = '';
      this.newStockProductId = null;
      this.returnedStockProducts = [];
      this.newStockProductOptions = [];
      this.updateStockProductId = null;
    },
    addFileUrlClicked(){
      this.addProjectFileUrl = true;
    },
    removeFileUrlClicked(){
      this.addProjectFileUrl = false;
    },
    addOrUpdateStockProduct(){
      let selectedProduct = this.returnedStockProducts.filter(product => product.id == this.newStockProductId);
      selectedProduct = selectedProduct[0];
      selectedProduct['quantity'] = this.newStockProductQty;
      if(!this.updateStockProductId){
        this.stockProducts.push(selectedProduct);
      }else{
        for(let i=0; i < this.stockProducts.length; i++){
          if(this.stockProducts[i].id == this.updateStockProductId){
            this.stockProducts[i] = selectedProduct;
            break;
          }
        }
      }
      let addComponentModal = Modal.getInstance($('#addComponentModal'));
      if(addComponentModal){
        addComponentModal.hide();
      }
      this.newStockProductQty = 1;
      this.newStockProductSearch = '';
      this.newStockProductId = null;
      this.returnedStockProducts = [];
      this.newStockProductOptions = [];
      this.updateStockProductId = null;
    },
    deleteStockProduct(id){
      for(let i=0; i < this.stockProducts.length; i++){
        if(this.stockProducts[i].id == id){
          this.stockProducts.splice(i, 1);
          break;
        }
      }
    },
    editStockProduct(id){
      for(let i=0; i < this.stockProducts.length; i++){
        if(this.stockProducts[i].id == id){
          let editProduct = this.stockProducts[i];
          this.newStockProductQty = editProduct.quantity;
          this.updateProductSearch(editProduct.upc);
          this.newStockProductId = editProduct.id;
          this.returnedStockProducts = [];
          this.newStockProductOptions = [];
          this.updateStockProductId = editProduct.id;
          break;
        }
      }
    },
    updateProductSearch(productSearch){
      if(productSearch){
        this.newStockProductSearch = productSearch;
        this.searchStockProducts(productSearch);
      }
    }
  },
});
</script>