<template>
  <BreadCrumb PageTitle="Add User" />
  <AddUser />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../template-components/Common/BreadCrumb.vue";
import AddUser from "../../template-components/Users/AddUser/AddUser.vue";

export default defineComponent({
  name: "AddUserPage",
  components: {
    BreadCrumb,
    AddUser,
  },
});
</script>