<template>
  <!-- Overlay -->
  <div v-if="isFullScreenLoading" class="full-overlay">
    <span class="spinner-border text-light spinner-overlay" role="status" aria-hidden="true"></span>
  </div>
    <!-- Modal -->
  <div class="modal fade" id="issueModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Create order issue</h1>
          <button type="button" class="btn-close" id="modalDismiss" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form v-on:submit.prevent="submitIssue">
          <div class="modal-body">
            <div class="mb-mb-15 mb-md-20">
              <label for="overviewEmail" class="form-label fw-medium">Search and select order:</label>
              <model-select
                  class="form-control shadow-none rounded-0 text-black"
                  placeholder="Order id, #, customer name or email"
                  name="issueOrderId"
                  v-model="issueOrderId"
                  :options="orderOptions"
                  @searchchange="updateOrderSearch"
                  >
              </model-select>
            </div>
            <div class="mb-mb-15 mb-md-20">
              <label for="overviewEmail" class="form-label fw-medium">Type of issue:</label>
              <select id="issueType" v-model="issueType" class="form-select shadow-none text-black fs-md-15" required>
                <option value="Bundle Issue">Bundle Issue</option>
                <option value="Wrong Address">Wrong Address</option>
                <option value="Wrong Products Set">Wrong Products Set</option>
                <option value="Missing Product">Missing Product</option>
                <option value="Wrong Order Sent">Wrong Order Sent</option>
                <option value="Damaged Order Product">Damaged Order Product</option>
                <option value="Tracking Not Updated">Tracking Not Updated</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div class="mb-15 mb-md-20">
                <label for="issueDescription" class="form-label fw-medium">Description</label>
                <textarea class="form-control shadow-none fs-md-15 text-black" id="issueDescription" v-model="issueDescription" placeholder="Required issue description" required></textarea>
                <div class="invalid-feedback">
                    Notes - Enter more information about the issue. (No need to include order numbers.)
                </div>
            </div>
            <!-- <div class="mb-15 mb-md-20">
              <ul v-if="orderDetails.order_number > 0" style="padding:0px">
                <li v-for="item in orderDetails.items.data" :key="item.id" style="margin:0px;list-style-type: none;">
                  <div class="input-group input-group-sm mb-15 mb-md-20" style="margin-bottom:0px !important">
                      <span class="input-group-text" id="inputGroup-sizing-sm">Quantity:</span>
                      <input type="number" class="form-control shadow-none text-black" value="0" style="max-width: 100px;" :data-item-id="item.id"  min="0">
                      <span class="input-group-text" id="basic-addon2">{{ item.stock_product.data.name }}</span>
                  </div>
                </li>
              </ul>
            </div> -->
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="submit" class="btn btn-success" :disabled="isLoading">Submit Issue</button>
          </div>
        </form>
      </div>

    </div>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">

    <div
      class="card-head box-shadow bg-white align-items-center justify-content-between p-15 p-sm-20"
    >
      <div class="d-md-flex align-items-center justify-content-between">
        <form class="search-box position-relative">
          <input
            type="text"
            class="form-control shadow-none text-black rounded-0 border-0"
            placeholder="Search order with issue"
            v-model="searchInput"
          />
          <button
            class="bg-transparent text-primary transition p-0 border-0"
            disabled
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <div class="d-sm-flex align-items-center">
          <button data-bs-toggle="modal" data-bs-target="#issueModal" type="button" 
            class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mt-md-0 text-decoration-none"
          >
            Create Issue
            <i class="flaticon-plus position-relative ms-5 fs-12"></i>
          </button>
        </div>
      </div>

    </div>
    <!-- Existing template code -->
    <div class="card-body p-15 p-sm-20 p-md-25 position-relative">
      <div v-if="isLoading" class="relative-overlay">
        <span class="spinner-border text-light spinner-overlay" role="status" aria-hidden="true"></span>
      </div>
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Order ID
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Order #
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Status
              </th>

              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Description
              </th>

              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Order Total
              </th>
<!--
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Items Applied
              </th> -->

              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                <SortableColumn column="created_at" :sortedBy="sortField" @sortClicked="sortClicked">Created</SortableColumn>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="issue in issues" :key="issue.id">
              <td><router-link :to="`/orders/${issue.order_id}`">{{ issue.order.data.id }}</router-link></td>
              <td>{{ issue.order.data.order_number }}</td>
              <td>{{ issue.issue_status_name}}</td>
              <td>{{ issue.description}}</td>
              <td>${{ issue.amount_paid}}</td>
              <!-- <td>{{ issue.items_applied}}</td> -->
              <td>{{ formatTimestamp(issue.created_at) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <PaginationFooter :pagination="meta.pagination" :sortField="sortField" :sortOrder="sortOrder" />
    </div>
  </div>
</template>

<script>
import "vue-search-select/dist/VueSearchSelect.css"
import { updatePageUrlWithSearch, currentPage, formatTimestamp } from "../../utils/helper";
import { ModelSelect } from "vue-search-select";
import SortableColumn from "../Common/SortableColumn.vue";
import PaginationFooter from "../Common/PaginationFooter.vue";
import apiConnector from '../../utils/apiConnector';
import showToast from '/src/utils/showToast';
import { Modal } from "bootstrap";
import $ from "jquery";

export default {
  name: "IssuesList",
  components: {
    SortableColumn,
    PaginationFooter,
    ModelSelect
  },
  data() {
    return {
      isLoading: true,
      isFullScreenLoading: false,
      sortField: 'created_at',
      sortOrder: 'desc',
      searchInput: '',
      issues: [],
      issueType: '',
      issueDescription: '',
      issueOrderSearch: '',
      issueOrderId: null,
      returnedOrders: [],
      orderOptions: [],
      meta: {
        pagination: {
          total: 0,
          count: 0,
          per_page: 20,
          current_page: 1,
          total_pages: 1,
          links: {
            next: null,
            previous: null,
          },
        },
      },
    };
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(newQuery) {
        this.sortField = newQuery.sort_by || 'created_at';
        this.sortOrder = newQuery.sort_order || 'desc';
        this.searchInput = newQuery.q || '';
        this.fetchIssues();
      },
    },
    searchInput(search){
      updatePageUrlWithSearch(1, this.sortField, this.sortOrder, this.searchInput, false, 'created_at');
    },
  },
  methods: {
    async fetchIssues() {
      this.isLoading = true;
      try {
        // let url = `/issues?page=${currentPage()}`;
        let url = `/issues?page=${currentPage()}&sorting[${this.sortField}]=${this.sortOrder}`;
        if (this.searchInput) url += `&q=${encodeURIComponent(this.searchInput)}`;
        const response = await apiConnector.makeGetRequest(url);
        this.issues = response.data.data;
        this.meta = response.data.meta;
      } catch (error) {
        console.error('There was an error fetching the issues:', error);
      } 
      finally {
        this.isLoading = false;
      }
    },
    async searchIssueOrders(search=null) {
      const response = await apiConnector.makeGetRequest(`/orders?excludes=order_items,items&q=${search}`);
      this.returnedOrders = response.data.data;
      this.orderOptions = this.returnedOrders.map(order => { return {
        text: 'ID ' + order.id + ' / #' + order.order_number + ': '+order.fulfillment_status+' ('+order.customer_first_name+' '+order.customer_last_name+'  / '+order.customer_email+')',
        value: order.id
      }});
    },
    async submitIssue() {
      if(!this.validates()){
        return false;
      }
      this.isFullScreenLoading = true;

      // Collect form data
      const formData = {
        order_id: this.issueOrderId,
        type: this.issueType,
        description: this.issueDescription
      };

      try {
        const response = await apiConnector.makePostRequest(`issues`, formData);
        showToast('Issue successfully created');
        this.fetchIssues();

        let issueModal = Modal.getOrCreateInstance($('#issueModal'));
        issueModal.hide();
        this.issueOrderId = null;
        this.issueType = '';
        this.issueDescription = '';
      } catch (error) {
        console.error(error);
        // Handle error (e.g., show an error message)
      } finally {
        this.isFullScreenLoading = false; // Hide the overlay and spinner
      }
    },
    validates(){
      let orderValidation = this.validateIssueOrder();
      let typeValidation = this.validateIssueType();
      let descriptionValidation = this.validateIssueDescription();
      return orderValidation && typeValidation && descriptionValidation;
    },
    validateIssueOrder(){
      if(!this.issueOrderId){
        $('input[name=issueOrderId]').addClass('is-invalid');
        return false
      }
      $('input[name=issueOrderId]').removeClass('is-invalid');
      return true;
    },
    validateIssueType(){
      if(!this.issueType){
        $('#issueType').addClass('is-invalid');
        return false
      }
      $('#issueType').removeClass('is-invalid');
      return true;
    },
    validateIssueDescription(){
      if(!this.issueDescription){
        $('#issueDescription').addClass('is-invalid');
        return false
      }
      $('#issueDescription').removeClass('is-invalid');
      return true;
    },
    sortClicked(sorting) {
      this.sortField = sorting.column;
      this.sortOrder = sorting.order;
      updatePageUrlWithSearch(1, this.sortField, this.sortOrder, this.searchInput, false, 'created_at');
    },
    updateOrderSearch(searchOrder){
      if(searchOrder.length > 3){
        this.issueOrderSearch = searchOrder;
        this.searchIssueOrders(searchOrder);
      }
    },
    formatTimestamp
  },
};
</script>
