<template>
  <BreadCrumb PageTitle="Notifications" />
  <NotificationsContent />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../template-components/Common/BreadCrumb.vue";
import NotificationsContent from "../../template-components/PagesInner/Notifications/NotificationsContent.vue";

export default defineComponent({
  name: "NotificationsPage",
  components: {
    BreadCrumb,
    NotificationsContent,
  },
});
</script>