<template>
  <BreadCrumb PageTitle="Course Details" />
  <CourseDetails />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../template-components/Common/BreadCrumb.vue";
import CourseDetails from "../../template-components/ELearning/CourseDetails/CourseDetails.vue";

export default defineComponent({
  name: "CourseDetailsPage",
  components: {
    BreadCrumb,
    CourseDetails,
  },
});
</script>