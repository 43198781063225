<template>
  <BreadCrumb PageTitle="Form Controls" />
  <FormControl />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../template-components/Common/BreadCrumb.vue";
import FormControl from "../../template-components/Forms/FormControl/FormControl.vue";

export default defineComponent({
  name: "FormControlPage",
  components: {
    BreadCrumb,
    FormControl,
  },
});
</script>