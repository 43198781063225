<template>
  <BreadCrumb PageTitle="Social Links" />
  <CoverImage />
  <SocialLinks />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../template-components/Common/BreadCrumb.vue";
import CoverImage from "../../template-components/Settings/CoverImage.vue";
import SocialLinks from "../../template-components/Settings/SocialLinks/SocialLinks.vue";

export default defineComponent({
  name: "SocialLinksPage",
  components: {
    BreadCrumb,
    CoverImage,
    SocialLinks,
  },
});
</script>