<template style="padding-left:0px">
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <h4 class="text-black fw-bold mb-0 text-center">
            <img src="https://shipbreeze.s3.us-east-2.amazonaws.com/ShipBreeze-Horizontal-Grey.png" style="max-width: 180px;" alt="logo-icon" />
          </h4>
          <form @submit.prevent="submitForm">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Email Address
              </label>
              <input
                type="email"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="john@shipbreeze.com"
                v-model="credentials.email"
              />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Password
              </label>
              <input
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="**************"
                v-model="credentials.password"
              />
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success d-block w-100"
              type="submit"
            >
              Sign In
            </button>
            <div class="text-center mt-15 mt-sm-20 mt-md-25">
              <router-link :to="{path: '/forgot-password'}" class="link-btn text-decoration-none text-secondary fs-md-15 fs-lg-16 position-relative ">
                Forgot password
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import router from "../../../router";
import apiConnector from '../../../utils/apiConnector';

export default {
  name: "LoginForm",
  data() {
    return {
      credentials: {
        email: '',
        password: ''
      }
    };
  },
  setup(){
    if(localStorage.getItem('shipbreezeAuthorized')){
      router.push({ path: '/' });
    }
  },
  methods: {
    submitForm() {
      apiConnector.login(this.credentials.email, this.credentials.password);
    }
  }
};
</script>

