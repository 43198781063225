<template>
  <BreadCrumb PageTitle="Sold Vs Backorders" />
  <SoldVsBackorders />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import SoldVsBackorders from "../../components/Inventory/SoldVsBackorders.vue";

export default defineComponent({
  name: "SoldVsBackordersPage",
  components: {
    BreadCrumb,
    SoldVsBackorders,
  },
});
</script>