import { Toast } from "bootstrap";

function showToast(message, error=false) {
  const liveToast = document.getElementById("liveToast");
  const toastContainer = document.getElementById("toastContainer")
  const toastElement = liveToast.cloneNode(true);
  toastContainer.appendChild(toastElement);
  if(error){
    toastElement.classList.remove('text-bg-success');
    toastElement.classList.add('text-bg-danger');
  }
  else if(!toastElement.classList.contains('text-bg-success')){
    toastElement.classList.add('text-bg-success');
    toastElement.classList.remove('text-bg-danger');
  }
  toastElement.querySelector(".toast-body").textContent = message;
  if (toastElement) {
    const toastBootstrap = new Toast(toastElement);
    toastBootstrap.show();
    toastElement.addEventListener('hidden.bs.toast', function () {
      toastElement.remove()
    })
  }
}

export default showToast;
