<template>
  <MainHeader v-if="!$route.meta.requiresNoSidebar" @warehouseScopeUpdated="warehouseScopeUpdated" />
  <MainSidebar v-if="!$route.meta.requiresNoSidebar && !$route.meta.template" />
  <MainSidebarTemplate v-if="!$route.meta.requiresNoSidebar && $route.meta.template" />
  <div class="main-content d-flex flex-column transition overflow-hidden">
    <router-view :key="warehouseId"  v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component" />
      </transition>
    </router-view>
    <MainFooter />
  </div>         
   <div id="toastContainer" class="toast-container position-fixed bottom-0 end-0 p-3">
    <div id="liveToast" class="toast hide text-bg-success align-items-center border-0" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="d-flex">
        <div class="toast-body fs-md-15 fs-lg-16">
          Success!
        </div>
        <button
          type="button"
          class="btn-close shadow-none btn-close-white me-2 m-auto"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, watchEffect } from "vue";
import stateStore from "./utils/store";

import MainHeader from "./components/Layouts/MainHeader.vue";
import MainSidebar from "./components/Layouts/MainSidebar.vue";
import MainSidebarTemplate from "./template-components/Layouts/MainSidebar.vue";
import MainFooter from "./components/Layouts/MainFooter.vue";


export default defineComponent({
  name: "App",
  components: {
    MainHeader,
    MainSidebar,
    MainSidebarTemplate,
    MainFooter,
  },
  data() {
    return {
      warehouseId: null,
    }
  },
  mounted() {
    document.body.classList.add("bg-body-secondary");

    // if (typeof window.bootstrap === "undefined") {
    //   console.log('loading bootstrap');
    //   const script = document.createElement("script");
    //   script.src =
    //     "https://cdn.jsdelivr.net/npm/bootstrap@5/dist/js/bootstrap.bundle.min.js";
    //   document.head.appendChild(script);
    // }
    const stateStoreInstance = stateStore;
    watchEffect(() => {
      if (stateStoreInstance.open || this.$route.meta.requiresNoSidebar) {
        document.body.classList.remove("sidebar-show");
        document.body.classList.add("sidebar-hide");
        console.log("show");
      } else {
        document.body.classList.remove("sidebar-hide");
        document.body.classList.add("sidebar-show");

        console.log("hide");
      }
    });
  },
  methods : {
    warehouseScopeUpdated(warehouseId) {
      console.log('warehouseScopeUpdated '+warehouseId);
      this.warehouseId = warehouseId;
    }
  }
});
</script>