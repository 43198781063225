<template>
  <div
    :class="[
      'sidebar-area position-fixed start-0 top-0 bg-black h-100vh transition',
      { active: stateStoreInstance.open },
    ]"
    id="sidebar-area"
  >
    <div class="logo position-absolute start-0 end-0 top-0 bg-black">
      <router-link
        to="/"
        class="d-flex align-items-center text-white text-decoration-none"
      >
        <img src="https://app.wehandleship.com/img/logos/ShipBreeze-Horizontal-White@3x.png" style="max-width: 180px;" alt="logo-icon" />
      </router-link>
      <div class="border-bottom"></div>
      <button
        class="sidebar-burger-menu position-absolute lh-1 bg-transparent p-0 border-0"
        @click="stateStoreInstance.onChange"
      >
        <i class="ph-duotone ph-caret-double-left"></i>
      </button>
    </div>
    <div class="sidebar-menu">
      <ul
        class="sidebar-navbar-nav ps-0 mb-0 list-unstyled accordion"
        id="sidebarNavAccordion"
      >
        <!-- <li
          class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
        >
          <a
            href="#"
            class="accordion-button rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapseOne"
            aria-expanded="true"
            aria-controls="sidebarCollapseOne"
          >
            <i class="flaticon-more-1"></i>
            <span class="title">Dashboard</span>
          </a>
          <div
            id="sidebarCollapseOne"
            class="accordion-collapse collapse show"
            data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link to="/orders" class="sidebar-sub-menu-link">
                    Orders
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/issues" class="sidebar-sub-menu-link">
                    Issues
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li> -->

        <li class="sidebar-nav-item">
          <router-link to="/orders" class="sidebar-nav-link d-block">
            <i class="ph ph-shopping-cart"></i>
            <span class="title">Orders</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/issues" class="sidebar-nav-link d-block">
            <i class="flaticon-warning"></i>
            <span class="title">Issues</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <a
            href="#"
            class="accordion-button rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapseProducts"
            aria-expanded="true"
            aria-controls="sidebarCollapseProducts"
          >
            <i class="ph ph-barcode"></i>
            <span class="title">Products</span>
          </a>
          <div
            id="sidebarCollapseProducts"
            class="accordion-collapse collapse show"
            data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link to="/stock-products" class="sidebar-sub-menu-link">
                    Stock Products
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/products" class="sidebar-sub-menu-link">
                    Virtual Products
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li
          class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
        >
          <a
            href="#"
            class="accordion-button rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapseInventory"
            aria-expanded="true"
            aria-controls="sidebarCollapseInventory"
          >
            <i class="flaticon-list"></i>
            <span class="title">Inventory</span>
          </a>
          <div
            id="sidebarCollapseInventory"
            class="accordion-collapse collapse show"
            data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link to="/inventory" class="sidebar-sub-menu-link">
                    Current Inventory
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/inventory/reports" class="sidebar-sub-menu-link">
                    Reports
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link to="/inventory/sold-vs-backorders" class="sidebar-sub-menu-link">
                    Sold vs Backorders
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/returns" class="sidebar-nav-link d-block">
            <i class="ph ph-arrow-arc-left"></i>
            <span class="title">Returns</span>
          </router-link>
        </li>
        <li class="sidebar-nav-item">
          <router-link to="/special-projects" class="sidebar-nav-link d-block">
            <i class="flaticon-project"></i>
            <span class="title">Special Projects</span>
          </router-link>
        </li>        
        <li class="sidebar-nav-item">
          <router-link to="/logout" class="sidebar-nav-link d-block">
            <i class="flaticon-logout"></i>
            <span class="title">Logout</span>
          </router-link>
        </li>
<!--         <li class="sidebar-nav-item position-fixed bottom-0 mb-20">
          <router-link to="/template" class="sidebar-nav-link d-block">
            <i class="flaticon-extension"></i>
            <span class="title">Adlash Template</span>
          </router-link>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import stateStore from "../../utils/store";

export default defineComponent({
  name: "MainSidebar",
  setup() {
    const stateStoreInstance = stateStore;
    return {
      stateStoreInstance,
    };
  },
});
</script>