<template>
  <div v-if="isLoading" style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); display: flex; justify-content: center; align-items: center; z-index: 9050;">
    <span class="spinner-border text-light" style="width: 3rem; height: 3rem;" role="status" aria-hidden="true"></span>
  </div>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div class="card-body">
      <div class="row align-items-center justify-content-between">
        <div class="col-auto">
          <div class="input-group mb-3">
            <span class="input-group-text" id="basic-addon1">Choose Dates</span>
            <input ref="daterangeInput" type="text" class="form-control" />
          </div>
          <div class="input-group mb-3">
            <label class="input-group-text" for="productSelect">Products</label>
            <select class="form-select" id="productSelect" v-model="selectedProductUpc" @change="fetchSoldVsBackorders">
              <option v-for="product in products" :key="product.upc" :value="product.upc">{{ product.name }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <h5>Units Needed</h5>

      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">Name</th>
              <th class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">UPC</th>
              <th class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">Qty Available for Sale</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="unit in unitsNeeded" :key="unit.UPC">
              <td>{{ unit.name }}</td>
              <td>{{ unit.UPC }}</td>
              <td>{{ unit.qty_avail_sale }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
      <div class="col-6">
        <h5>Units Sold</h5>
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">Product</th>
              <th class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">UPC</th>
              <th class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">Units Sold</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="unit in unitsSold" :key="unit.upc">
              <td>{{ unit.product }}</td>
              <td>{{ unit.upc }}</td>
              <td>{{ unit.units_sold }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import apiConnector from '../../utils/apiConnector';
import moment from 'moment';
import 'daterangepicker';
import 'daterangepicker/daterangepicker.css';

export default {
  name: "SoldVsBackorders",
  data() {
    return {
      isLoading: false,
      products: [],
      selectedProductUpc: '',
      startDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
      endDate: moment().format('YYYY-MM-DD'),
    };
  },
  created() {
    this.fetchProducts();
  },
  mounted() {
    $(this.$refs.daterangeInput).daterangepicker({
      startDate: moment().subtract(29, 'days'),
      endDate: moment(),
      ranges: {
         'Today': [moment(), moment()],
         'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
         'Last 7 Days': [moment().subtract(6, 'days'), moment()],
         'Last 30 Days': [moment().subtract(29, 'days'), moment()],
         'This Month': [moment().startOf('month'), moment().endOf('month')],
         'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    }, (start, end) => {
      this.startDate = start.format('YYYY-MM-DD');
      this.endDate = end.format('YYYY-MM-DD');
      this.fetchSoldVsBackorders();
    });
  },
  methods: {
    async fetchProducts() {
        this.isLoading = true;
        try {
          const endpoint = `${process.env.VUE_APP_API_BASE_URL}/inventory/products`;
          const response = await apiConnector.makeGetRequest(endpoint);
          this.products = response.data.data;
          if (this.products.length > 0) {
            this.selectedProductUpc = this.products[0].upc;
            this.fetchSoldVsBackorders();
          }
        }
        catch(error){
          console.error("Error fetching products:", error);
        }
        finally{
          this.isLoading = false;
        }
    },
    async fetchSoldVsBackorders() {
      if (!this.selectedProductUpc) return;
      
      this.isLoading = true;
        try {
          const endpoint = `${process.env.VUE_APP_API_BASE_URL}/inventory/soldVsBackorders?end_date=${this.endDate}&upc=${this.selectedProductUpc}&start_date=${this.startDate}`;
          const response = await apiConnector.makeGetRequest(endpoint);
          console.log(response.data);
          const { units_needed, units_sold } = response.data.data;
          this.unitsNeeded = units_needed;
          this.unitsSold = units_sold;
        }
        catch(error){
          console.error("Error fetching sold vs backorders data:", error);
        }
        finally{
          this.isLoading = false;
        }
    },
  },
};
</script>
