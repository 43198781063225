<template>
  <BreadCrumb PageTitle="Current Inventory" />
  <InventoryList />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import InventoryList from "../../components/Inventory/InventoryList.vue";

export default defineComponent({
  name: "InventoryPage",
  components: {
    BreadCrumb,
    InventoryList,
  },
});
</script>