import apiConnector from '../utils/apiConnector';
import router from "../router";

export const FULFILLMENT_STATUS = {
  PENDING: 1,
  ON_HOLD: 6,
  CAN_RELEASE_STATUSES: [6],
  CAN_HOLD_STATUSES: [1, 11],
};

export const canHoldOrder = (statusId) => FULFILLMENT_STATUS.CAN_HOLD_STATUSES.includes(statusId);
export const canReleaseOrder = (statusId) => FULFILLMENT_STATUS.CAN_RELEASE_STATUSES.includes(statusId);


export const holdOrder = async (orderId) => {
  try {

    const response = await apiConnector.makePatchRequest(`orders/${orderId}`, {
      fulfillment_status_id: FULFILLMENT_STATUS.ON_HOLD,
    });
    return response.data;
  } catch (error) {
    console.error('Error holding the order:', error);
    throw error;
  }
};

export const releaseOrder = async (orderId) => {
  try {
    const response = await apiConnector.makePatchRequest(`orders/${orderId}`, {
      fulfillment_status_id: FULFILLMENT_STATUS.PENDING,
    });
    return response.data;
  } catch (error) {
    console.error('Error releasing the order:', error);
    throw error;
  }
};

export const formatDate = (date, longMonth=false) => {
  const options = { year: 'numeric', month: longMonth ? 'long' : 'numeric', day: 'numeric' };
  return new Date(date).toLocaleString(undefined, options);
}

export const formatDateToSql = (date) => {
  return new Date(date).toISOString().slice(0, 10);
}

export const formatTimestamp = (date, longMonth=false) => {
  const options = { year: 'numeric', month: longMonth ? 'long' : 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  return new Date(date).toLocaleString(undefined, options);
}

export const getTodayDateSqlFormat = () => {
  return formatDateToSql(new Date);
}

export const get7DaysAgoDateSqlFormat = () => {
  return formatDateToSql((new Date).setDate((new Date).getDate() - 7));
}

export const updatePageUrlWithSearch = (page, sortField, sortOrder, search=false, newHistory=false, defaultSortField='updated_at', defaultSortOrder='desc') => {
  let query = {};
  if(page > 1){
    query.page = page;
  }  
  if(search){
    query.q = search;
  }
  if(sortField != defaultSortField || sortOrder != defaultSortOrder){
    query.sort_by = sortField;
    query.sort_order = sortOrder;
  }
  let routeObj = { name: router.currentRoute.value.name, query: query };
  if(newHistory){
    router.push(routeObj).catch(err => { console.log(err) });
  }else{
    router.replace(routeObj).catch(err => { console.log(err) });
  }
};

export const goToPage = (page) => {
  let routeObj = { name: router.currentRoute.value.name, query: {...router.currentRoute.value.query, page: (page == 1 ? undefined : page)} };
  router.replace(routeObj).catch(err => { console.log(err) });
}

export const updatePageUrl = (page, sortField, sortOrder, newHistory=true, defaultSortField='updated_at', defaultSortOrder='desc') => {
  updatePageUrlWithSearch(page, sortField, sortOrder, null, newHistory, defaultSortField, defaultSortOrder);
};

export const currentPage = () => {
  return router.currentRoute.value.query.page || '1';
}
