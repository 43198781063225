<template>
  <div
    class="pagination-area d-md-flex mt-15 mt-sm-20 mt-md-25 justify-content-between align-items-center"
  >
    <p class="mb-0 text-paragraph">
      Showing <span class="fw-bold">{{ pagination.count }}</span> out of
      <span class="fw-bold">{{ pagination.total }}</span> results
    </p>
    <nav class="mt-15 mt-md-0">
      <ul class="pagination mb-0">
        <li class="page-item" :class="{ disabled: !pagination.links.previous }">
          <a class="page-link" href="#" @click.prevent="goToPage(pagination.current_page-1)" aria-label="Previous">
            <i class="flaticon-chevron-1"></i>
          </a>
        </li>
        <li class="page-item" v-for="page in paginationRange" :key="page" :class="{ active: page === pagination.current_page }">
          <a class="page-link" href="#" @click.prevent="goToPage(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ disabled: !pagination.links.next }">
          <a class="page-link" href="#" @click.prevent="goToPage(pagination.current_page+1)" aria-label="Next">
            <i class="flaticon-chevron"></i>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { goToPage } from "../../utils/helper";

export default defineComponent({
  name: "PaginationFooter",
  props: ["pagination", "sortField", "sortOrder", "search", "defaultSortField", "defaultSortOrder"],
  methods: {
    goToPage
    // goToPage(page) {
    //   let defaultSortField = this.defaultSortField || 'updated_at';
    //   let defaultSortOrder = this.defaultSortOrder || 'desc';
    //   updatePageUrlWithSearch(page, this.sortField, this.sortOrder, this.search, true, defaultSortField, defaultSortOrder);
    // },
  },
  computed: {
    paginationRange() {
      const range = [] as Array<any>;
      const total_pages = this.pagination.total_pages;
      const current_page = this.pagination.current_page;
      const rangeSize = 5;
      let start = current_page - Math.floor(rangeSize / 2);
      start = Math.max(start, 1);
      start = Math.min(start, 1 + total_pages - rangeSize);
      if(start < 1){
        start = 1;
      }

      for (let i = 0; i < Math.min(rangeSize, total_pages); i++) {
        range.push(start + i);
      }

      return range;
    },
  },
});
</script>