<template>
  <BreadCrumb PageTitle="Connected Accounts" />
  <ConnectedAccounts />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "../../template-components/Common/BreadCrumb.vue";
import ConnectedAccounts from "../../template-components/PagesInner/ConnectedAccounts/ConnectedAccounts.vue";

export default defineComponent({
  name: "ConnectedAccountsPage",
  components: {
    BreadCrumb,
    ConnectedAccounts,
  },
});
</script>